import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import Content from '@/Components/Content';
import InputDropdown from '@/Components/InputDropdown';
import InputLabel from '@/Components/InputLabel';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import type { ProjectAction } from '@/Context/Actions/project-actions';
import {
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useUpdateMarketSubmission } from '@/Hooks/Submission';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import type { CategoryProps } from '@/Types/Projects';
import type { SectionHandles } from '@/Types/Submission';
import {
  MarketInformation,
  type MarketInformationSchema,
} from '@/Types/Submission/schema';
import type { UserResponse } from '@/Types/user';
import { triggerGTMEvent } from '@/Utils/gtm';

import ActionButtons from './Action';

const fieldsToDisplay = [
  { name: 'product_category', label: 'Product Category', required: true },
  {
    name: 'product_subcategory',
    label: 'Product Subcategory',
    required: true,
  },
];

interface MarketInformationSectionProps {
  activeSection: number;
  projectSlug: string;
  user: UserResponse;
  isComplete: boolean;
  data: {
    product_category: number;
    product_subcategory: number;
  };
  isActive: boolean;
  isEditor: boolean;
  isEditingCount: number;
  categories: { title: string; value: number }[];
  subcategories: CategoryProps[];
  onRefetchSubmission: () => void;
  onDispatch: React.Dispatch<ProjectAction>;
  onSetIsEditingCount: React.Dispatch<React.SetStateAction<number>>;
  onSetActiveSection: React.Dispatch<React.SetStateAction<number>>;
}

const MarketInformationSection = forwardRef<
  SectionHandles,
  MarketInformationSectionProps
>(
  (
    {
      activeSection,
      projectSlug,
      user,
      data,
      isActive,
      isEditor,
      isEditingCount,
      isComplete,
      categories,
      subcategories,
      onDispatch,
      onRefetchSubmission,
      onSetIsEditingCount,
      onSetActiveSection,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const project = useMemo(() => state.project, [state.project]);
    const isEnabledHistoryStatus = useMemo(
      () => state.isEnabledHistoryStatus,
      [state.isEnabledHistoryStatus],
    );

    const {
      isMarketResearchDemand,
      isMarketResearchTrend,
      isTargetAudienceAudienceInsight,
      isTargetAudienceBehavioralTrend,
      isTargetAudienceMediaConsumptionPattern,
      isTargetAudiencePerceptionAnalysis,
    } = useHistoryStatus(project, isEnabledHistoryStatus);

    const { mutate: generate } = useGenerate();
    const { mutate } = useUpdateMarketSubmission();
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const [subcategoriesOption, setSubcategoriesOption] = useState<
      { title: string; value: number }[]
    >([]);

    const defaultValues = useMemo(
      () => ({
        product_category: data?.product_category ?? 0,
        product_subcategory: data?.product_subcategory ?? 0,
      }),
      [data.product_category, data.product_subcategory],
    );

    const {
      handleSubmit,
      reset,
      control,
      setValue,
      watch,
      trigger,
      formState: { isLoading, isValid },
    } = useForm<MarketInformationSchema>({
      resolver: zodResolver(MarketInformation),
      defaultValues,
      mode: 'all',
    });

    const selectedCategoryId = watch('product_category');

    const toggleIsEditing = useCallback(
      (isEdit: boolean) => {
        setIsEditing(isEdit);
        if (onSetIsEditingCount) {
          const newCount = isEdit ? isEditingCount + 1 : isEditingCount - 1;
          onSetIsEditingCount(newCount);
        }
      },
      [isEditingCount, onSetIsEditingCount],
    );

    const onSubmit = useCallback(
      async (data: MarketInformationSchema) => {
        triggerGTMEvent({
          event: 'Edit Submission Market Information',
          eventCategory: 'Button Edit Submission Market Information Click',
          eventAction: 'Click',
          eventLabel: 'Submission Market Information',
          userId: user.email,
          data,
        });

        toggleIsEditing(true);
        mutate(
          {
            payload: data,
            projectSlug,
          },
          {
            onSuccess: () => {
              triggerGTMEvent({
                event: 'Generate Market Demand',
                eventCategory: 'Button Generate Market Demand Click',
                eventAction: 'Click',
                eventLabel: 'Generate Market Demand',
                userId: user.email,
                data: projectSlug,
              });

              triggerGTMEvent({
                event: 'Generate Market Trend',
                eventCategory: 'Button Generate Market Trend Click',
                eventAction: 'Click',
                eventLabel: 'Generate Market Trend',
                userId: user.email,
                data: projectSlug,
              });

              generate({
                payload: {
                  generate_more: '',
                  regenerate_prompt: '',
                  status: 'regenerate from submission',
                },
                projectSlug,
                section: 'market-research/demand',
              });

              generate({
                payload: {
                  generate_more: '',
                  regenerate_prompt: '',
                  status: 'regenerate from submission',
                },
                projectSlug,
                section: 'market-research/trends',
              });

              if (isTargetAudienceAudienceInsight.status) {
                triggerGTMEvent({
                  event: 'Generate Audience Insight',
                  eventCategory: 'Button Generate Audience Insight Click',
                  eventAction: 'Click',
                  eventLabel: 'Generate Audience Insight',
                  userId: user.email,
                  data: projectSlug,
                });

                generate({
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    status: 'regenerate from submission',
                  },
                  projectSlug,
                  section: 'target-audience/audience-insight',
                });
              }

              if (isTargetAudienceBehavioralTrend.status) {
                triggerGTMEvent({
                  event: 'Generate Behavioral Trend',
                  eventCategory: 'Button Generate Behavioral Trend Click',
                  eventAction: 'Click',
                  eventLabel: 'Generate Behavioral Trend',
                  userId: user.email,
                  data: projectSlug,
                });

                generate({
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    status: 'regenerate from submission',
                  },
                  projectSlug,
                  section: 'target-audience/behavioral-trends',
                });
              }
              if (isTargetAudienceMediaConsumptionPattern.status) {
                triggerGTMEvent({
                  event: 'Generate Media Consumption Pattern',
                  eventCategory:
                    'Button Generate Media Consumption Pattern Click',
                  eventAction: 'Click',
                  eventLabel: 'Generate Media Consumption Pattern',
                  userId: user.email,
                  data: projectSlug,
                });

                generate({
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    status: 'regenerate from submission',
                  },
                  projectSlug,
                  section: 'target-audience/media-consumption-patterns',
                });
              }

              if (isTargetAudiencePerceptionAnalysis.status) {
                triggerGTMEvent({
                  event: 'Generate Perception Analysis',
                  eventCategory: 'Button Generate Perception Analysis Click',
                  eventAction: 'Click',
                  eventLabel: 'Generate Perception Analysis',
                  userId: user.email,
                  data: projectSlug,
                });

                generate({
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                    status: 'regenerate from submission',
                  },
                  projectSlug,
                  section: 'target-audience/perception-analysis',
                });
              }

              onDispatch(setEnableHistoryStatus(true));
              onRefetchSubmission();
              toggleIsEditing(false);
            },
          },
        );
      },
      [
        isTargetAudienceAudienceInsight,
        isTargetAudienceBehavioralTrend,
        isTargetAudienceMediaConsumptionPattern,
        isTargetAudiencePerceptionAnalysis,
      ],
    );

    useImperativeHandle(ref, () => ({
      save: async () => {
        await handleSubmit(onSubmit)();
        onSetActiveSection(activeSection + 1);
      },
      element: divRef.current, // Ekspos elemen HTMLDivElement
    }));

    useEffect(() => {
      if (isEditing) {
        onDispatch(setEnableHistoryStatus(false));
        reset({
          product_category: data?.product_category ?? '',
          product_subcategory: data?.product_subcategory ?? '',
        });
      } else {
        onDispatch(setEnableHistoryStatus(true));
      }
    }, [isEditing]);

    const handleCancel = () => {
      setIsEditing(false);
      reset({
        product_category: data?.product_category ?? 0,
        product_subcategory: data?.product_subcategory ?? 0,
      });
    };

    const isSaveDisabled = useMemo(() => !isValid, [isValid]);

    useEffect(() => {
      if (selectedCategoryId) {
        const filteredSubcategories = subcategories
          .filter(
            (item: CategoryProps) => +item.parent_id === +selectedCategoryId,
          )
          .map((item: CategoryProps) => ({
            title: item.name,
            value: item.id,
          }));

        setValue('product_subcategory', data.product_subcategory ?? 0);
        trigger('product_subcategory');
        setSubcategoriesOption(filteredSubcategories);
      } else {
        setSubcategoriesOption([]);
      }
    }, [selectedCategoryId, data.product_subcategory]);

    useEffect(() => {
      if (data.product_category) {
        const filteredSubcategories = subcategories
          .filter(
            (item: CategoryProps) => +item.parent_id === data.product_category,
          )
          .map((item: CategoryProps) => ({
            title: item.name,
            value: item.id,
          }));

        setSubcategoriesOption(filteredSubcategories);
      }
    }, [data.product_category]);

    const handleSave = useCallback(async () => {
      if (
        isMarketResearchDemand.isQuerying ||
        isMarketResearchTrend.isQuerying
      ) {
        onDispatch(
          saveSubInformation(
            `{text-error-redx} Please wait market research is still generating`,
          ),
        );
        return;
      }

      if (isMarketResearchDemand.status || isMarketResearchTrend.status) {
        setIsModalOpen(true);
        return;
      }
      await handleSubmit(onSubmit)();
    }, [
      isMarketResearchDemand.status,
      isMarketResearchTrend.status,
      isMarketResearchDemand.isQuerying,
      isMarketResearchTrend.isQuerying,
    ]);

    return (
      <div
        ref={divRef}
        className={`mb-24 flex w-full flex-col ${
          isActive ? '' : 'cursor-not-allowed opacity-30'
        }`}
      >
        <div className="flex w-full items-center justify-between">
          <h1 className="mb-15 text-24 font-bold leading-32">
            Market Information
          </h1>
          <ActionButtons
            isActive={isActive && isComplete}
            isEditing={isEditing}
            isEditor={isEditor}
            isFetching={false}
            isValid={isSaveDisabled}
            onCancel={handleCancel}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
          />
        </div>
        <div className="grid w-full grid-cols-2 gap-24">
          {fieldsToDisplay.map(({ name, label, required }) => {
            const isCategoryField = name === 'product_category';
            const fieldList = isCategoryField
              ? categories
              : subcategoriesOption;

            return (
              <div key={name} className="flex flex-col gap-10">
                <InputLabel optional={!required} value={label} />
                {isEditing || !isComplete ? (
                  <Controller
                    control={control}
                    name={name as keyof MarketInformationSchema}
                    render={({ field: { value }, fieldState: { error } }) => {
                      return (
                        <InputDropdown
                          containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                          disabled={!isActive}
                          error={error?.message}
                          list={fieldList}
                          modelValue={value}
                          multiple={false}
                          onChange={(value) => {
                            setValue(
                              name as keyof MarketInformationSchema,
                              +value,
                            );
                            trigger(name as keyof MarketInformationSchema);
                          }}
                          placeholder={`Choose ${label} here`}
                          withFilter
                        />
                      );
                    }}
                  />
                ) : (
                  <Content
                    isFetching={false}
                    isMarkDown={false}
                    value={
                      fieldList.find(
                        (item) =>
                          item.value === data[name as keyof typeof data],
                      )?.title || '-'
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
        <ChangingImpactModal
          handleSubmit={async () => {
            await handleSubmit(onSubmit)();
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          isRegenerating={isLoading}
          onClose={() => {
            handleCancel();
            toggleIsEditing(true);
            setIsModalOpen(false);
          }}
          section="product-information"
          visibleSections={[
            'Market Research: Industry',
            'Target Audience',
            'Audience Archetype',
            'Key Insight',
            'Challenge & Communication Task',
            'One-Page Strategy',
            'Key Touch Point',
            'Idea Alignment',
            'Idea Personalization',
            'Idea Expansion',
            'Searchability Content',
            'Discoverability Content',
            'Credibility Content',
            'Selected Content Ideas',
          ]}
        />
      </div>
    );
  },
);

export default memo(MarketInformationSection);
