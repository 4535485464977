import { TabGroup } from '@headlessui/react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCtaAction,
  useIdeaPersonalization,
  useSectionDataHandler,
  useUpdateIdeaPersonalization,
} from '@/Hooks/IdeaPersonalization';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  ArchetypeIdeaPersonalization,
  ArchetypesState,
  SectionIdeaPersonalization,
} from '@/Types/IdeaPersonalization';
import type { IdeaPersonalizationSchema } from '@/Types/IdeaPersonalization/schema';
import type { SourcesType } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';
import { getArchetypeDetails } from '@/Utils/archetype';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import Skeleton from './Components/Skeleton';
import Tabs from './Components/Tabs';

const Index = () => {
  const { mutate: generate, isPending: isRegenerating } = useGenerate();
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const sectionList: SectionList = {
    title: 'Idea Personalization',
    value: 'personalization',
    section: 'idea',
  };
  const navigate = useNavigate();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [message, setMessage] = useState('');
  const { roles } = useRolesBadge(project, user);

  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);

  const { isIdeaPersonalization, isIdeaExpansion, isKeyTouchPoint } =
    useHistoryStatus(project, isEnabledHistoryStatus);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } = useIdeaPersonalization(
    project.slug,
  );
  const [totals, setTotals] = useState<{
    allSelected: number;
    allData: number;
  }>({
    allSelected: 0,
    allData: 0,
  });
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate, isPending: isSubmitting } = useUpdateIdeaPersonalization();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype
  const archetypesArray = useMemo(
    () => Object.values(archetypes),
    [archetypes],
  );
  const [sources, setSources] = useState<SourcesType[]>([]);

  const { data: differentArchetypes, refetch: refetchDiffArchetype } =
    useDifferentArchetypes(project.slug);

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Challenge & Task',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  useSectionDataHandler({
    diffArch: differentArchetypes,
    isIdeaExpansion,
    archetypes,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionKey: sectionList.value,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    setSources,
    setTotalRegenerate,
    setTotals,
    setMessage,
  });

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: sectionList.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
    isQuerying: isIdeaPersonalization.isQuerying,
  });

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const selectedIndexes = useMemo(() => {
    return activeArchetype?.idea_personalizations
      .map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [activeArchetype?.idea_personalizations]);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    payload: IdeaPersonalizationSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeArchetype?.id!, projectSlug },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Idea Personalization`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Idea Personalization  Click`,
            eventAction: 'Click',
            eventLabel: 'Idea Personalization',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    if (!activeArchetype) return;

    setArchetypes((prev) => {
      const currentKey = prev[activeArchetype.id]?.idea_personalizations || [];
      const filteredKey = currentKey.filter((datum) => datum.id !== -1);

      return {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          idea_personalizations: filteredKey,
        },
      };
    });

    toggleIsAdding();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAdd = () => {
    if (!activeArchetype) return;

    const newData: SectionIdeaPersonalization = {
      id: -1, // Gunakan ID sementara
      archetype_id: activeArchetype.id,
      is_edited: false,
      content_number: 1,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: false,
      is_manual: true,
      idea_personalization_content: {
        idea_description: '',
        idea_personalization_number: 0,
        idea_title: '',
        reason_product_brand: '',
        reason_target_audience: '',
      },
    };

    // Hitung panjang array baru
    const currentIdeaPersonalization =
      archetypes[activeArchetype.id]?.idea_personalizations || [];
    const newIndex = currentIdeaPersonalization.length;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          idea_personalizations: [...currentIdeaPersonalization, newData],
        },
      };
      return updatedArchetypes;
    });

    setGoToIndex(newIndex);
    toggleIsAdding(); // Aktifkan mode "Adding"
  };

  const handleRegenerate = useCallback(() => {
    generate(
      {
        projectSlug: project.slug,
        section: 'idea-expansion',
        payload: {
          generate_more: '',
          regenerate_prompt: '',
          ...((activeArchetype?.has_regenerate_per_archetype_idea_expansions ||
            activeArchetype?.is_diff_idea_personalization) && {
            status: 'regenerate per archetype from idea personalization',
            archetype_id: [activeArchetype.id],
          }),
        },
      },
      {
        onSuccess: () => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        },
      },
    );
  }, [
    routeOptionValue,
    activeArchetype?.has_regenerate_per_archetype_idea_expansions,
    isIdeaExpansion.isRegenerated,
    activeArchetype?.is_diff_idea_personalization,
  ]);

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.value,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeIdeaPersonalization>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Idea Personalization`,
      eventCategory: `Generate More Idea Personalization Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Personalization',
      userId: user.email,
      data: prompt,
    });

    generate(
      {
        payload: {
          generate_more: prompt,
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'idea-personalization',
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.allSelected} from ${totals.allData} idea personalization selected`,
      ),
    );
  }, [totals]);

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useRoutePrev({
    id: 'btn-back-idea-personalization',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/idea-alignment`),
  });

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useCtaAction({
    differentArchetypes,
    isIdeaExpansion,
    isKeyTouchPoint,
    totalSelected: totals.allSelected,
    activeArchetype,
    archetypesArray,
    isEditing,
    isEditor,
    project,
    routeOptionValue,
    message,
    setErrorTabIndex,
    setRouteOption: setRouteOptionValue,
    setShowViewInfoModal,
    setShowRegenerateModal,
  });

  const handleClick = () => {
    navigate(`/${project.slug}/idea-alignment`);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [isIdeaPersonalization.isQuerying, isIdeaExpansion.isQuerying]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Idea Personalization
            </h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one idea for each archetype
              </span>
              , edit, or add by clicking &apos;Add Idea Manually&apos;.
            </span>
          </div>
          {activeArchetype &&
            isEditor &&
            !activeArchetype.is_querying &&
            message !== 'No data found' && (
              <RegenerateButton
                isGenerateMore
                isLoading={isLoading}
                limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="idea-personalization"
              />
            )}
        </div>

        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs
            activeTab={activeTab}
            archetypesArray={archetypesArray}
            errorTabIndex={errorTabIndex}
            isEditing={isEditing || isAdding}
            isLoading={isLoading}
            isQuerying={isIdeaPersonalization.isQuerying}
            onActiveTab={setActiveTab}
            user={user}
          />
          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              key={activeArchetype?.id || activeTab}
              className="h-full py-24"
            >
              {(() => {
                if (message === 'No data found') {
                  return (
                    <NotDataFound
                      buttonLabel="Go to Audience Archetype"
                      description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
                      onClick={() =>
                        navigate(`/${project.slug}/audience-archetype`)
                      }
                      title="No Data Found."
                    />
                  );
                }

                if (!activeArchetype) {
                  return null; // Jangan tampilkan apa-apa
                }

                if (activeArchetype.is_querying) {
                  return (
                    <GenerateLoading
                      progress={progressPortion({
                        progress: Number(
                          (progressStates[activeArchetype.id] || 0).toFixed(0),
                        ),
                        isQuerying: activeArchetype.is_querying,
                        isContentAvailable:
                          activeArchetype.idea_personalizations.length > 0,
                      })}
                      project={project}
                      section={sectionList}
                      showEmailNotification={
                        isIdeaPersonalization.isSendingEmail
                      }
                    />
                  );
                }

                if (
                  activeArchetype.is_regenerate ||
                  activeArchetype.idea_personalizations.length === 0
                ) {
                  return (
                    <NotDataFound
                      buttonLabel="Choose Idea alignment"
                      description=" To show results on idea personalization, we need idea alignment. Click button below to choose Idea alignment.."
                      onClick={handleClick}
                      title="You don’t idea personalization..."
                    />
                  );
                }

                return (
                  <div className="flex flex-col gap-20 ">
                    <SelectedCard
                      data={getArchetypeDetails(
                        activeArchetype.archetype_content,
                      )}
                      title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                    />
                    <SliderContainer
                      goToIndex={goToIndex}
                      isAdding={isAdding}
                      isEditing={isEditing}
                      length={activeArchetype.idea_personalizations.length}
                      onActiveIndex={(index) => setActiveIndex(index)}
                      selectedIndexes={selectedIndexes}
                    >
                      {activeArchetype.idea_personalizations.map(
                        (datum, index) => (
                          <Card
                            key={index}
                            ref={(el) => (cardRefs.current[index] = el)}
                            id={datum.id}
                            index={index}
                            isCanSelect={isEditor}
                            isDisabledSelectButton={
                              !isIdeaExpansion.isRegenerated &&
                              activeArchetype.total_idea_expansions > 0 &&
                              !differentArchetypes?.is_different_archetype
                                .challenges &&
                              !differentArchetypes?.is_different_archetype
                                .idea_expansions &&
                              activeArchetype.total_selected_idea_personalizations ===
                                1 &&
                              !activeArchetype.has_regenerate_per_archetype_idea_expansions
                            }
                            isEdited={datum.is_edited || datum.is_manual}
                            isEditing={isEditing || isAdding}
                            isFetching={isRefetching}
                            isSelected={!!datum.is_selected}
                            item={datum.idea_personalization_content}
                            onSelectSuccess={onSelectSuccess}
                            onSubmitSuccess={handleFetchSubmit}
                            project={project}
                            refetchDiffArchetype={refetchDiffArchetype}
                            totalData={
                              activeArchetype.idea_personalizations.length
                            }
                            user={user}
                          />
                        ),
                      )}
                    </SliderContainer>

                    <ActionSection
                      isEditing={isAdding || isEditing}
                      isEditor={isEditor}
                      isSubmitting={isSubmitting}
                      label="Add Idea Personalization Manually"
                      onAdd={handleAdd}
                      onCancel={isAdding ? handleCancelAdd : handleCancelEdit}
                      onSave={handleSubmit}
                      toggleEditing={toggleIsEditing}
                    />

                    {sources && <SourcesList sources={sources} />}
                  </div>
                );
              })()}
            </div>
          )}
        </TabGroup>
      </div>
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="idea-personalization"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="key-insight"
        visibleSections={[
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
