import type { RefObject } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';
import type { SectionHandles } from '@/Types/Submission';

import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  currentRef: RefObject<SectionHandles> | null;
  isEditor: boolean;
  isEditing: boolean;
  isActiveCta: boolean;
  isIdeaAlignmentComplete: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  currentRef,
  isEditor,
  isEditing,
  isActiveCta,
  project,
  isIdeaAlignmentComplete,
  routeOptionValue,
  setShowViewInfoModal,
  setRouteOption,
}: UseCtaParams) => {
  const [state, dispatch] = useContext(ProjectContext);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const navigate = useNavigate();
  const projectSlug = project.slug;

  const { isIdeaAlignment } = useHistoryStatus(project, isEnabledHistoryStatus);

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(async () => {
    if (isIdeaAlignment.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait idea alignment is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isIdeaAlignment.status &&
      isIdeaAlignmentComplete &&
      !isIdeaAlignment.isRegenerated
    ) {
      navigate(`/${project.slug}/idea-alignment`);
      return;
    }

    if (currentRef?.current?.save) {
      await currentRef.current.save();
    }
  }, [
    isEditing,
    isIdeaAlignment.status,
    isIdeaAlignment.isQuerying,
    isIdeaAlignment.isRegenerated,
    isIdeaAlignmentComplete,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (
      !isIdeaAlignment.status ||
      !isIdeaAlignmentComplete ||
      isIdeaAlignment.isRegenerated
    ) {
      setShowViewInfoModal(true);
      return;
    }

    if (isIdeaAlignment.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait audience archetype is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/idea-alignment`);
  }, [
    isEditing,
    isIdeaAlignment.status,
    isIdeaAlignment.isQuerying,
    isIdeaAlignment.isRegenerated,
    isIdeaAlignmentComplete,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = isActiveCta;
    if (isEditor) {
      memoizedRouteOptionValue.isInactive = isIdeaAlignment.isQuerying;

      if (
        !isIdeaAlignment.status ||
        !isIdeaAlignmentComplete ||
        isIdeaAlignment.isRegenerated
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Idea Alignment';
      } else {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Idea Alignment';
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.isInactive =
        !isIdeaAlignment.status ||
        isIdeaAlignment.isQuerying ||
        isIdeaAlignment.isRegenerated;

      memoizedRouteOptionValue.label = 'Next to Idea Alignment';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    isIdeaAlignment.status,
    isEditor,
    isEditing,
    isIdeaAlignmentComplete,
    isIdeaAlignment.isQuerying,
    isIdeaAlignment.isRegenerated,
    isActiveCta,
  ]);
};

export { useCtaAction };
