import { Icon } from '@iconify/react';
import React, { useCallback, useEffect, useState } from 'react';

import type { Idea } from '@/Types/CredibilityContent';
import { capitalizeWords } from '@/Utils/string';

import Checkbox from './Checkbox';
import TextInput from './TextInput';

interface ChecklistSectionProps {
  recommendationNumber?: number;
  archetypeId?: number;
  isFetching?: boolean;
  isEditor: boolean;
  title: string;
  items?: Pick<Idea, 'id' | 'value' | 'is_checked'>[];
  onItemChange?: (
    archetypeId: number,
    recommendationNumber: number,
    title: string,
    id: string,
    checked: boolean,
  ) => void;
  onAddItem?: (
    formStatus: string,
    archetypeId: number,
    recommendationNumber: number,
    title: string,
    items: Pick<Idea, 'id' | 'value' | 'is_checked'>[],
  ) => void;
  onStatusChange?: (status: 'idle' | 'adding' | 'editing') => void;
}

const ChecklistSection: React.FC<ChecklistSectionProps> = ({
  recommendationNumber,
  archetypeId,
  isFetching = false,
  isEditor,
  title,
  items,
  onItemChange,
  onAddItem,
  onStatusChange,
}) => {
  const [statusAction, setStatusAction] = useState<string>('');
  const [tempItems, setTempItems] = useState<
    Pick<Idea, 'id' | 'value' | 'is_checked'>[]
  >([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const isAdding = statusAction === 'add';
  const isEditing = statusAction === 'edit';

  const handleSave = useCallback(() => {
    if (!tempItems) return;

    const updatedItems = tempItems.map((item) => {
      if (item.id === 'temp') {
        return {
          ...item,
          id: Date.now().toString(),
        };
      }
      return item;
    });

    // Validate items
    const newErrors: Record<string, string> = {};
    updatedItems.forEach((item, index) => {
      if (item.value!.trim() === '') {
        newErrors[index] = 'This field is required';
      }
    });

    setErrors(newErrors);

    // If no errors, proceed
    if (Object.keys(newErrors).length === 0) {
      onAddItem?.(
        isAdding ? 'add' : 'update',
        archetypeId!,
        recommendationNumber!,
        title,
        updatedItems,
      );
      setTempItems(updatedItems);
      setStatusAction('');
      onStatusChange?.('idle');
    }
  }, [tempItems, isAdding]);

  const handleAddButton = useCallback(() => {
    if (statusAction !== 'edit') {
      setStatusAction('add');
      onStatusChange?.('adding');
    }

    setTempItems((prev) => [
      ...prev,
      { id: 'temp', value: '', is_checked: false }, // Add a temporary item
    ]);
  }, [statusAction]);

  const handleEditButton = () => {
    setStatusAction('edit');
    onStatusChange?.('editing');
  };

  const handleChange = (index: number, value: string) => {
    if (!tempItems) return;

    const updatedItems = tempItems.map((item, idx) =>
      idx === index ? { ...item, value } : item,
    );

    // Clear error for the current index if the value is valid
    const updatedErrors = { ...errors };
    if (value.trim() !== '') {
      delete updatedErrors[index];
    } else {
      updatedErrors[index] = 'This field is required';
    }

    setTempItems(updatedItems);
    setErrors(updatedErrors);
  };

  const handleCancel = () => {
    items && setTempItems(items);
    onStatusChange?.('idle');
    setTempItems((prev) => prev?.filter((item) => item.id !== 'temp')); // Remove the temporary item
    setStatusAction(''); // Exit adding mode
  };

  useEffect(() => {
    items && setTempItems(items);
  }, [items]);

  if (isFetching) {
    return (
      <div className="border-b mb-6 w-full pb-4">
        {/* Section Header */}
        <div className="mb-4 flex items-center justify-between">
          <div className="mt-37 flex w-full  animate-pulse rounded-full border-1 border-soft-purple-redx bg-soft-purple-redx px-24 py-4" />
        </div>

        {/* Checklist Items */}
        <ul className="flex flex-col gap-8">
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <li key={index} className="flex items-center">
                <div className="mt-37 flex w-full  animate-pulse rounded-full border-1 border-soft-purple-redx bg-soft-purple-redx px-24 py-4" />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <div className="border-b mb-6 w-full pb-4">
      {/* Section Header */}
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-16 font-semibold leading-19 text-black-redx">
          {capitalizeWords(title)}
        </h3>
        {isEditor && tempItems?.length && !isAdding && !isEditing ? (
          <button
            className="flex items-center font-semibold text-blue-redx hover:underline"
            onClick={handleEditButton}
          >
            Edit
            <Icon
              className="ml-4 max-h-20 max-w-20"
              height={20}
              icon="lucide:edit"
              width={20}
            />
          </button>
        ) : null}
        {(isAdding || isEditing) && (
          <div className="inline-flex gap-16">
            <button
              className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
              id="btn-cancel-edit-submission-target-audience"
              onClick={handleCancel}
            >
              <span className="text-15 font-semibold">Cancel</span>
              <Icon className="size-16" icon="lucide:x" />
            </button>

            <button
              className="inline-flex cursor-pointer items-center gap-x-4"
              id="btn-edit-submission-audience-archetype"
              onClick={handleSave}
            >
              <p className="text-14 font-semibold text-blue-redx">Save</p>
              <Icon className="size-16 text-blue-redx" icon="lucide:save" />
            </button>
          </div>
        )}
      </div>

      {/* Checklist Items */}
      <ul className="flex flex-col gap-8">
        {tempItems.map((item, index) => {
          return (
            <li key={item.id} className="flex items-center">
              {item.id !== 'temp' && !isEditing && (
                <Checkbox
                  key={`${index}_${item.is_checked}`}
                  checked={item.is_checked}
                  disabled={isAdding || isEditing}
                  isEditor={isEditor}
                  label={item.value}
                  onChange={(checked) =>
                    onItemChange?.(
                      archetypeId!,
                      recommendationNumber!,
                      title,
                      item.id.toString(),
                      checked,
                    )
                  }
                />
              )}
              {(item.id === 'temp' || isEditing) && (
                <div className="flex w-full flex-col gap-4">
                  <TextInput
                    autoComplete="product_name"
                    className="ml-8 block w-full !text-14 leading-20"
                    error={errors[index]}
                    hasCheckBox
                    id={`${item.id}_${title.toLowerCase().replace(' ', '_')}`}
                    isCheckboxChecked={item.is_checked}
                    isFocused={false}
                    isMultiline
                    name={`${item.id}_${title.toLowerCase().replace(' ', '_')}_label`}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >,
                    ) => handleChange(index, e.target.value)}
                    placeholder={`Input ${title.toLowerCase()} here`}
                    value={item.value}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>

      {/* Add Button */}
      {isEditor && (
        <button
          className="mt-4 flex items-center text-14 font-normal leading-20 text-placeholder-redx  hover:text-black-redx"
          onClick={() => handleAddButton()}
        >
          <Icon
            className="mr-2 max-h-24 max-w-24"
            height={24}
            icon="lucide:plus"
            width={24}
          />
          Click to add {title.toLowerCase()} manually
        </button>
      )}
      {(isAdding || isEditing) && (
        <div className="mt-12 max-w-fit rounded-4 bg-soft-grey-redx px-8 py-4 text-12 font-semibold leading-14 text-black-redx">
          Your edits or changes will not affect the output on the source and
          previous steps
        </div>
      )}
    </div>
  );
};

export default ChecklistSection;
