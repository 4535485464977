import AudienceArchetypeRoutes from './AudienceArchetype';
import AuthRoutes from './Auth';
import ChallengeAndTaskRoutes from './ChallengeAndTask';
import CompetitorAnalysisRoutes from './CompetitorAnalysis';
import CredibilityContentRoutes from './CredibilityContent';
import DiscoverabilityContentRoutes from './DiscoverabilityContent';
import HomeRoutes from './Home';
import IdeaAlignmentRoutes from './IdeaAlignment';
import IdeaExpansionRoutes from './IdeaExpansion';
import IdeaPersonalizationRoutes from './IdeaPersonalization';
import IdeaSubmissionRoutes from './IdeaSubmission';
import KeyInsightRoutes from './KeyInsights';
import KeyTouchPointRoutes from './KeyTouchPoint';
import MarketResearchRoutes from './MarketResearch';
import NotFoundRoutes from './NotFound';
import OnePageStrategyRoutes from './OnePageStrategy';
import OpportunityAmongCompetitionRoutes from './OpportunityAmongCompetition';
import ProductResearchRoutes from './ProductResearch';
import Projects from './Projects';
import SearchabilityContentRoutes from './SearchabilityContent';
import SelectedContentIdeasRoutes from './SelectedContentIdeas';
import SubmissionRoutes from './Submission';
import TargetAudienceRoutes from './TargetAudience';

const routes = [
  ...HomeRoutes,
  ...AuthRoutes,
  ...Projects,
  ...SubmissionRoutes,
  ...MarketResearchRoutes,
  ...AudienceArchetypeRoutes,
  ...KeyInsightRoutes,
  ...TargetAudienceRoutes,
  ...ProductResearchRoutes,
  ...CompetitorAnalysisRoutes,
  ...OpportunityAmongCompetitionRoutes,
  ...ChallengeAndTaskRoutes,
  ...OnePageStrategyRoutes,
  ...KeyTouchPointRoutes,
  ...IdeaSubmissionRoutes,
  ...IdeaPersonalizationRoutes,
  ...IdeaExpansionRoutes,
  ...IdeaAlignmentRoutes,
  ...SearchabilityContentRoutes,
  ...CredibilityContentRoutes,
  ...DiscoverabilityContentRoutes,
  ...SelectedContentIdeasRoutes,
  ...NotFoundRoutes,
];

export default routes;
