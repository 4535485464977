import { isEqual } from 'lodash';
import { useEffect } from 'react';

import type { IdeaAlignmentResponse, SectionData } from '@/Types/IdeaAlignment';
import { calculateTotals } from '@/Utils/calculate-totals';
import {
  initializeGeneratingStates,
  initializeProgressStates,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataParams {
  data: IdeaAlignmentResponse | undefined;
  activeSection: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  sectionData: Record<string, SectionData>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<Record<string, SectionData>>
  >;
  setTotals: React.Dispatch<
    React.SetStateAction<{
      allSelected: number;
      allData: number;
    }>
  >;
}

export const useSectionData = ({
  data,
  activeSection,
  projectSlug,
  progressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
  sectionData,
  setTotals,
}: UseSectionDataParams) => {
  useEffect(() => {
    if (data) {
      const existingData = sectionData[activeSection];

      if (data.is_querying) {
        const dataArray = [
          {
            id: data.history_id,
            ...data,
          },
        ];

        const initialStates = initializeGeneratingStates(dataArray);
        setGeneratingStates(initialStates);

        const savedProgress = getSectionProgressFromLocalStorage(
          projectSlug,
          activeSection,
        );

        const updatedProgressStates = initializeProgressStates(
          dataArray, // Data array yang sudah diubah
          savedProgress,
          progressStates,
        );

        setProgressStates((prev) => ({
          ...prev,
          ...updatedProgressStates,
        }));
      }

      if (!isEqual(existingData, data)) {
        setSectionData((prev) => ({
          ...prev,
          [activeSection]: {
            is_email_notification: data.is_email_notification,
            history_id: data.history_id ?? 0,
            content: data.content ?? [],
            sources: data.sources ?? [],
            total_regenerate: data.total_regenerate ?? 0,
            is_querying: data.is_querying,
            previous_selected: data.previous_selected,
            message: data.message,
          },
        }));
      }

      const result = calculateTotals(
        data,
        'total_idea_alignments',
        'total_selected_idea_alignments',
      );

      setTotals(result);
    }
  }, [data, activeSection]);
};
