import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';

import { useDifferentArchetypes } from '../AudienceArchetype';
import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  routeOptionValue: RouteOption;
  isEdited: boolean;
  isEditor: boolean;
  isQuerying: boolean;
  message: string;
  isEditingCount: number;
  project: ProjectProps;
  setShowViewInfoModal: (value: boolean) => void;
  checkSelectedArchetypeHasChanging: () => void;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  isEdited,
  routeOptionValue,
  isEditor,
  isQuerying,
  isEditingCount,
  message,
  project,
  setShowViewInfoModal,
  checkSelectedArchetypeHasChanging,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  const {
    isAudienceArchetypeComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isUpdateState,
  } = useHistoryStatus(project);

  const { data: differentArchetypes } = useDifferentArchetypes(project.slug);

  const archetypeCount = differentArchetypes?.selected_archetype ?? 0;
  const isDiffOppor =
    differentArchetypes?.is_different_archetype.opportunities ?? false;

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(() => {
    if (isCompetitorAnalysisComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait competitor is still generating`,
        ),
      );
      return;
    }

    if (isOpportunityAmongCompetitionComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, idea is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (archetypeCount === 0) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please generate & choose Audience Archetype first. asd`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditingCount > 0) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (
      isOpportunityAmongCompetitionComplete.status &&
      !isDiffOppor &&
      !isEdited &&
      !isOpportunityAmongCompetitionComplete.isRegenerated
    ) {
      navigate(`/${project.slug}/opportunity-among-competition`);
      return;
    }

    checkSelectedArchetypeHasChanging();
  }, [
    memoizedRouteOptionValue,
    isUpdateState,
    isOpportunityAmongCompetitionComplete.isRegenerated,
    isCompetitorAnalysisComplete.isQuerying,
    isOpportunityAmongCompetitionComplete.isQuerying,
    isOpportunityAmongCompetitionComplete.status,
    isAudienceArchetypeComplete.status,
    archetypeCount,
    isEditingCount,
    isDiffOppor,
    isEdited,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (isCompetitorAnalysisComplete.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait competitor is still generating`,
        ),
      );
      return;
    }

    if (
      !isOpportunityAmongCompetitionComplete.status ||
      isDiffOppor ||
      isOpportunityAmongCompetitionComplete.isRegenerated
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/opportunity-among-competition`);
  }, [
    isUpdateState,
    isOpportunityAmongCompetitionComplete.isRegenerated,
    isCompetitorAnalysisComplete.isQuerying,
    isOpportunityAmongCompetitionComplete.status,
    isDiffOppor,
    projectSlug,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';

    if (isEditor) {
      memoizedRouteOptionValue.isInactive =
        isQuerying ||
        isCompetitorAnalysisComplete.isQuerying ||
        !isCompetitorAnalysisComplete.status ||
        archetypeCount === 0 ||
        isOpportunityAmongCompetitionComplete.isQuerying ||
        isEditingCount > 0;

      if (
        (isOpportunityAmongCompetitionComplete.status &&
          isOpportunityAmongCompetitionComplete.isRegenerated &&
          !isDiffOppor) ||
        isEdited
      ) {
        memoizedRouteOptionValue.label = 'Update other pages';
        memoizedRouteOptionValue.isGenerate = true;
      } else if (
        isOpportunityAmongCompetitionComplete.status &&
        !isDiffOppor &&
        !isOpportunityAmongCompetitionComplete.isRegenerated &&
        !isEdited
      ) {
        memoizedRouteOptionValue.label =
          'Next to Opportunity Among Competition';
        memoizedRouteOptionValue.isGenerate = false;
      } else {
        memoizedRouteOptionValue.label =
          'Generate Opportunity Among Competition';
        memoizedRouteOptionValue.isGenerate = true;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.label = 'Next to Opportunity Among Competition';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.isInactive =
        !isOpportunityAmongCompetitionComplete.status ||
        isOpportunityAmongCompetitionComplete.isQuerying ||
        isDiffOppor;

      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    isUpdateState,
    isEditor,
    isQuerying,
    isEditingCount,
    isAudienceArchetypeComplete.status,
    isCompetitorAnalysisComplete.status,
    isCompetitorAnalysisComplete.isQuerying,
    isOpportunityAmongCompetitionComplete.isRegenerated,
    isOpportunityAmongCompetitionComplete.status,
    isOpportunityAmongCompetitionComplete.isQuerying,
    archetypeCount,
    isDiffOppor,
    isEdited,
    message,
  ]);
};

export { useCtaAction };
