import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { ProjectProps } from '@/Types/Projects';

import { useDifferentArchetypes } from '../AudienceArchetype';
import { useGenerate } from '../useGenerate';
import { useHistoryStatus } from '../useHistoryStatus';

interface UseCtaParams {
  message: string;
  isEditor: boolean;
  isEdited: boolean;
  isEditing: boolean;
  project: ProjectProps;
  routeOptionValue: RouteOption;
  activeSection: string;
  listArchetypeKeyTouchPoints: number[];
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalFramework: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  message,
  isEditor,
  isEditing,
  isEdited,
  project,
  routeOptionValue,
  activeSection,
  listArchetypeKeyTouchPoints,
  setShowRegenerateModal,
  setModalFramework,
  setShowViewInfoModal,
  setRouteOption,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  const { isOnePageStrategy, isKeyTouchPoint } = useHistoryStatus(project);
  const { data } = useDifferentArchetypes(project.slug);
  const { mutate: generate } = useGenerate();

  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const handleEditorClick = useCallback(async () => {
    if (isOnePageStrategy.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait one page is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation('{text-error-redx} Please do save first.'));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (isKeyTouchPoint.isQuerying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, key touch point is still generating`,
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    if (listArchetypeKeyTouchPoints.length > 0 || isEdited) {
      setShowRegenerateModal(true);
      return;
    }

    if (
      (data?.is_different_archetype.key_touch_points &&
        isKeyTouchPoint.status) ||
      isKeyTouchPoint.isRegenerated
    ) {
      dispatch(
        saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: true }),
      );
      generate(
        {
          payload: {
            generate_more: '',
            regenerate_prompt: '',
          },
          projectSlug: project.slug,
          section: 'key-touch-point',
        },
        {
          onSuccess: () => {
            navigate(`/${project.slug}/key-touch-point`);
          },
        },
      );
      dispatch(
        saveRouteNext({ ...memoizedRouteOptionValue, isDisabled: false }),
      );
      return;
    }

    if (!isKeyTouchPoint.status) {
      setModalFramework(true);
      return;
    }

    navigate(`/${projectSlug}/key-touch-point`);
  }, [
    listArchetypeKeyTouchPoints.length,
    isEditing,
    memoizedRouteOptionValue,
    isOnePageStrategy.isQuerying,
    isKeyTouchPoint.isQuerying,
    isKeyTouchPoint.status,
    data?.is_different_archetype.key_touch_points,
    isKeyTouchPoint.isRegenerated,
    isEdited,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (!isKeyTouchPoint.status || isKeyTouchPoint.isRegenerated) {
      setShowViewInfoModal(true);
      return;
    }

    if (isOnePageStrategy.isQuerying) {
      dispatch(
        saveSubInformation(
          '{text-error-redx} Please wait one page is generating.',
        ),
      );

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    navigate(`/${projectSlug}/key-touch-point`);
  }, [isEditing, isKeyTouchPoint.status, isOnePageStrategy.isQuerying]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No record found';
    memoizedRouteOptionValue.isInactive =
      isOnePageStrategy.isQuerying ||
      isEditing ||
      !isOnePageStrategy.status ||
      isKeyTouchPoint.isQuerying;

    if (isEditor) {
      if (
        isKeyTouchPoint.status &&
        !isKeyTouchPoint.isRegenerated &&
        !data?.is_different_archetype.key_touch_points &&
        listArchetypeKeyTouchPoints.length === 0 &&
        !isEdited
      ) {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Key Touch Point';
      } else if (
        isKeyTouchPoint.status &&
        !isKeyTouchPoint.isRegenerated &&
        !data?.is_different_archetype.key_touch_points &&
        (listArchetypeKeyTouchPoints.length || isEdited)
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Update other pages';
      } else {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Key Touch Point';
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.isInactive =
        !isKeyTouchPoint.status ||
        isKeyTouchPoint.isQuerying ||
        isKeyTouchPoint.isRegenerated;

      memoizedRouteOptionValue.label = 'Next to Key Touch Point';
      memoizedRouteOptionValue.isGenerate = false;
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    activeSection,
    isEditor,
    isEditing,
    listArchetypeKeyTouchPoints,
    isOnePageStrategy.isQuerying,
    isOnePageStrategy.status,
    isKeyTouchPoint.isQuerying,
    isKeyTouchPoint.status,
    isKeyTouchPoint.isRegenerated,
    isEdited,
    data?.is_different_archetype.key_touch_points,
    message,
  ]);
};

export { useCtaAction };
