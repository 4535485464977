import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type {
  CredibilityArchetype,
  Framework,
  Section,
} from '@/Types/CredibilityContent';
import type { StepId } from '@/Types/CredibilityContent/schema';
import { stepPropertyMap } from '@/Types/CredibilityContent/schema';
import type { HistoryStatus } from '@/Types/history';
import type { SelectedFramework } from '@/Types/Projects';
import type { Step } from '@/Types/step';

import { useUpdateLastStatusSubmission } from '../Submission';
import { useGenerate } from '../useGenerate';

interface UseCtaParams {
  credibilityIdea: HistoryStatus;
  credibilityObjective: HistoryStatus;
  credibilityKeyword: HistoryStatus;
  credibilityTopic: HistoryStatus;
  message: string;
  activeArchetype: CredibilityArchetype;
  steps: Step[];
  activeTab: number;
  projectSlug: string;
  totalArchetypes: number;
  currStep: number;
  isEditor: boolean;
  selectedFramework: SelectedFramework;
  refetch: () => void;
  onSetCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  onSetActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const useCtaAction = ({
  credibilityIdea,
  credibilityObjective,
  credibilityKeyword,
  credibilityTopic,
  activeArchetype,
  message,
  steps,
  currStep,
  isEditor,
  activeTab,
  totalArchetypes,
  projectSlug,
  selectedFramework,
  refetch,
  onSetCurrentStep,
  onSetActiveTab,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const { mutate: generate, isPending: isRegenerating } = useGenerate();
  const { mutate: updateLastStatus } = useUpdateLastStatusSubmission();

  const memoizedRouteOptionValue: RouteOption = useMemo(
    () => ({
      label: 'Generate Credibility Trends',
      isActive: true,
      isGenerate: true,
      isInactive: false,
      isDisabled: false,
    }),
    [],
  );

  const memoizedRouteBackOptionValue: RouteOption = useMemo(
    () => ({
      label: 'Back',
      isActive: true,
      isGenerate: true,
      isInactive: false,
      isDisabled: false,
    }),
    [],
  );

  const isAnyChecked = (frameworks: Framework[]): boolean => {
    return frameworks.every((framework) =>
      framework.data.some((entry) =>
        entry.ideas.some((idea) => idea.is_checked),
      ),
    );
  };

  useEffect(() => {
    const lastStepper = activeArchetype?.contents.last_stepper_status;
    const indexSelected =
      steps.findIndex((item) => item.id === lastStepper) ?? 0;

    currStep === -1 && onSetCurrentStep(indexSelected);
  }, [activeArchetype, currStep]);

  useEffect(() => {
    const currentStepId = steps[currStep]?.id as StepId;
    const propertyName = stepPropertyMap[currentStepId];
    const selectedStepData = activeArchetype?.contents[propertyName] as
      | Section
      | undefined;

    if (!selectedStepData) return;

    if (selectedFramework !== 'sdrcp' || message === 'No data found') {
      memoizedRouteOptionValue.isActive = false;
      memoizedRouteBackOptionValue.isActive = false;
    } else {
      switch (currStep) {
        case 0:
        case -1: {
          const stepName = stepPropertyMap[steps[1]?.id as StepId];

          memoizedRouteBackOptionValue.isActive = false;
          memoizedRouteOptionValue.isInactive = !isAnyChecked(
            selectedStepData.data,
          );
          memoizedRouteOptionValue.onClick = () => {
            const isValid = isAnyChecked(selectedStepData.data);
            if (!isValid) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} each framework must selected at least one.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (isRegenerating) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} please wait perceptions is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (credibilityKeyword.status) {
              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'credibility',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            if (isEditor) {
              generate(
                {
                  projectSlug,
                  section: 'content/credible/perceptions', // TODO: need to be check with api
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                  },
                },
                {
                  onSuccess: () => {
                    updateLastStatus(
                      {
                        archetypeId: activeArchetype.id,
                        projectSlug,
                        section: 'credibility',
                        status: stepName,
                      },
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  },
                },
              );
            }
          };

          if (!isEditor) {
            memoizedRouteOptionValue.isGenerate = false;
            memoizedRouteOptionValue.label = `Next to Perceptions (Archetype ${activeTab + 1})`;
            memoizedRouteOptionValue.isDisabled =
              !selectedStepData.status_next_step;
          } else {
            memoizedRouteOptionValue.isGenerate =
              !selectedStepData.status_next_step;
            memoizedRouteOptionValue.label = selectedStepData.status_next_step
              ? `Next to Perceptions (Archetype ${activeTab + 1})`
              : `Generate Perceptions (Archetype ${activeTab + 1})`;
          }

          break;
        }

        case 1: {
          const stepBackName = stepPropertyMap[steps[0]?.id as StepId];
          const stepName = stepPropertyMap[steps[2]?.id as StepId];
          memoizedRouteOptionValue.isInactive = !isAnyChecked(
            selectedStepData.data,
          );
          memoizedRouteBackOptionValue.label = 'Back to Goals';
          memoizedRouteBackOptionValue.isActive = true;
          memoizedRouteBackOptionValue.onClick = () => {
            updateLastStatus(
              {
                archetypeId: activeArchetype.id,
                projectSlug,
                section: 'credibility',
                status: stepBackName,
              },
              {
                onSuccess: () => refetch(),
              },
            );
          };
          memoizedRouteOptionValue.onClick = () => {
            const isValid = isAnyChecked(selectedStepData.data);
            if (!isValid) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} each framework must selected at least one.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (isRegenerating) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} please wait topics is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (credibilityTopic.status) {
              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'credibility',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            if (isEditor) {
              generate(
                {
                  projectSlug,
                  section: 'content/credible/topics',
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                  },
                },
                {
                  onSuccess: () => {
                    updateLastStatus(
                      {
                        archetypeId: activeArchetype.id,
                        projectSlug,
                        section: 'credibility',
                        status: stepName,
                      },
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  },
                },
              );
            }
          };

          if (!isEditor) {
            memoizedRouteOptionValue.isGenerate = false;
            memoizedRouteOptionValue.label = `Next to Topics (Archetype ${activeTab + 1})`;
            memoizedRouteOptionValue.isDisabled =
              !selectedStepData.status_next_step;
          } else {
            memoizedRouteOptionValue.isGenerate =
              !selectedStepData.status_next_step;
            memoizedRouteOptionValue.label = selectedStepData.status_next_step
              ? `Next to Topics (Archetype ${activeTab + 1})`
              : `Generate Topics (Archetype ${activeTab + 1})`;
          }
          break;
        }

        case 2: {
          const stepBackName = stepPropertyMap[steps[1]?.id as StepId];
          const stepName = stepPropertyMap[steps[3]?.id as StepId];

          memoizedRouteBackOptionValue.label = 'Back to Perceptions';
          memoizedRouteBackOptionValue.isActive = true;
          memoizedRouteBackOptionValue.onClick = () => {
            updateLastStatus(
              {
                archetypeId: activeArchetype.id,
                projectSlug,
                section: 'credibility',
                status: stepBackName,
              },
              {
                onSuccess: () => refetch(),
              },
            );
          };

          memoizedRouteOptionValue.isInactive = !isAnyChecked(
            selectedStepData.data,
          );
          memoizedRouteOptionValue.onClick = () => {
            const isValid = isAnyChecked(selectedStepData.data);
            if (!isValid) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} each framework must selected at least one.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }
            if (isRegenerating) {
              dispatch(
                saveSubInformation(
                  '{text-error-redx} please wait ideas is generating.',
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (credibilityIdea.status) {
              updateLastStatus(
                {
                  archetypeId: activeArchetype.id,
                  projectSlug,
                  section: 'credibility',
                  status: stepName,
                },
                {
                  onSuccess: () => refetch(),
                },
              );
              return;
            }

            if (isEditor) {
              generate(
                {
                  projectSlug,
                  section: 'content/credible/ideas',
                  payload: {
                    generate_more: '',
                    regenerate_prompt: '',
                  },
                },
                {
                  onSuccess: () => {
                    updateLastStatus(
                      {
                        archetypeId: activeArchetype.id,
                        projectSlug,
                        section: 'stepName',
                        status: stepName,
                      },
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  },
                },
              );
            }
          };

          if (!isEditor) {
            memoizedRouteOptionValue.isGenerate = false;
            memoizedRouteOptionValue.label = `Next to Ideas (Archetype ${activeTab + 1})`;
            memoizedRouteOptionValue.isDisabled =
              !selectedStepData.status_next_step;
          } else {
            memoizedRouteOptionValue.isGenerate =
              !selectedStepData.status_next_step;
            memoizedRouteOptionValue.label = selectedStepData.status_next_step
              ? `Next to Ideas (Archetype ${activeTab + 1})`
              : `Generate Ideas (Archetype ${activeTab + 1})`;
          }
          break;
        }

        default: {
          const stepBackName = stepPropertyMap[steps[2]?.id as StepId];
          memoizedRouteBackOptionValue.label = 'Back to Topics';
          memoizedRouteBackOptionValue.isActive = true;
          memoizedRouteBackOptionValue.onClick = () => {
            updateLastStatus(
              {
                archetypeId: activeArchetype.id,
                projectSlug,
                section: 'credibility',
                status: stepBackName,
              },
              {
                onSuccess: () => refetch(),
              },
            );
          };

          memoizedRouteOptionValue.isGenerate = false;

          if (activeTab < totalArchetypes - 1) {
            memoizedRouteOptionValue.label = `Next to Credibility Content (Archetype ${activeTab + 2})`;
            memoizedRouteOptionValue.onClick = () =>
              onSetActiveTab((oldVal) => oldVal + 1);
          } else {
            memoizedRouteOptionValue.label = `Next to Selected Content`;
            memoizedRouteOptionValue.onClick = () =>
              navigate(`/${projectSlug}/selected-content-ideas`);
          }
        }
      }
    }

    memoizedRouteOptionValue.isInactive = isRegenerating;
    dispatch(saveRouteNext(memoizedRouteOptionValue));
    dispatch(saveRoutePrev(memoizedRouteBackOptionValue));
  }, [
    activeArchetype,
    steps,
    currStep,
    isEditor,
    selectedFramework,
    message,
    credibilityIdea,
    credibilityObjective,
    credibilityKeyword,
    credibilityTopic,
    isRegenerating,
  ]);
};

export { useCtaAction };
