/* eslint-disable consistent-return */
import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import ChecklistSection from '@/Components/ChecklistSectionProps';
import GenerateLoading from '@/Components/GenerateLoading';
import NotDataFound from '@/Components/NotDataFound';
import ProgressLine from '@/Components/ProgressLine';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SectionInformation from '@/Components/Projects/SectionInformation';
import SourcesList from '@/Components/Projects/SourcesList';
import { saveInformation } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import {
  useCtaAction,
  useSearchability,
  useUpdateSearchability,
  useUpdateSelectedSearchability,
} from '@/Hooks/Searchability';
import { useUpdateLastStatusSubmission } from '@/Hooks/Submission';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type {
  Framework,
  Idea,
  SearchabilityArchetype,
  Section,
} from '@/Types/SearchabilityContent';
import { StepId, stepPropertyMap } from '@/Types/SearchabilityContent/schema';
import type { Step } from '@/Types/step';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import {
  getProgressFromLocalStorage,
  removeProgressFromLocalStorage,
  saveProgressToLocalStorage,
} from '@/Utils/persist-progress';
import { capitalizeWords } from '@/Utils/string';

import type { CardHandles } from './components/Card';
import CategoryTabs from './components/CategoryTabs';
import { Skeleton } from './components/Skeleton';
import StepHeader from './components/StepHeader';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const {
    data: searchabilityData,
    isPending: isFetching,
    refetch,
  } = useSearchability({
    project,
  });

  const maxGenerateLimit = project.max_generated_data;
  const tabsRef = useRef([]);
  const { roles } = useRolesBadge(project, user);
  const [currentStep, setCurrentStep] = useState(-1);
  const [isAdding, setIsAdding] = useState<number>(0);
  const [progressData, setProgressData] = useState<
    Record<number, Record<string, number>>
  >({});
  const isEditor = roles.includes('Owner') || roles.includes('Content');
  const [parsedData, setParsedData] = useState<SearchabilityArchetype[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const { mutate: submitUpdateSelected } = useUpdateSelectedSearchability();
  const { mutate: submitUpdate } = useUpdateSearchability();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const {
    searchabilityIdea,
    searchabilityObjective,
    searchabilityKeyword,
    searchabilityTopic,
  } = useHistoryStatus(project, isEnabledHistoryStatus);

  const { mutate: updateLastStatus } = useUpdateLastStatusSubmission();

  const tabsCategoryTypeRef = useRef<
    Record<number, Record<string, (HTMLElement | null)[]>>
  >({});

  const [activeCategoryTabState, setActiveCategoryTabState] = useState<
    Record<number, Record<string, number>>
  >({});

  const [selectedTitleState, setSelectedTitleState] = useState<
    Record<number, Record<string, string>>
  >({});

  const cardRefs = useRef<
    Record<
      number, // archetype_id
      Record<
        string, // framework
        Record<string, (CardHandles | null)[]> // title
      >
    >
  >({});

  const activeArchetype = useMemo(() => {
    return parsedData[activeTab] || null;
  }, [parsedData, activeTab]);

  const steps: Step[] = useMemo(() => {
    return [
      {
        id: StepId.GOALS_SUCCESS_METRICS_MAIN_FEATURES,
        label: 'Goals, Main Features, Success Measurement, & Aligned Big Idea',
        icon: <Icon icon="lucide:land-plot" />,
        status:
          (activeArchetype?.contents.goals_success_metrics_main_features
            ?.status ||
            activeArchetype?.contents.last_stepper_status ===
              'goals_success_metrics_main_features') ??
          false,
        isGenerating:
          (activeArchetype?.contents.goals_success_metrics_main_features
            ?.is_querying &&
            searchabilityObjective.isQuerying) ??
          false,
        statusNextStep:
          activeArchetype?.contents.goals_success_metrics_main_features
            ?.status_next_step ?? false,
      },
      {
        id: StepId.KEYWORD_TO_RANK_UP,
        label: 'Keywords to Rank Up',
        icon: <Icon icon="lucide:text-cursor-input" />,
        status:
          (activeArchetype?.contents.keywords_to_rank_up?.status ||
            activeArchetype?.contents.last_stepper_status ===
              'keywords_to_rank_up') ??
          false,
        isGenerating:
          (activeArchetype?.contents.keywords_to_rank_up?.is_querying &&
            searchabilityKeyword.isQuerying) ??
          false,
        statusNextStep:
          activeArchetype?.contents.keywords_to_rank_up?.status_next_step ??
          false,
      },
      {
        id: StepId.CONTENT_TOPICS,
        label: 'Content Topics',
        icon: <Icon icon="lucide:messages-square" />,
        status:
          (activeArchetype?.contents.content_topics?.status ||
            activeArchetype?.contents.last_stepper_status ===
              'content_topics') ??
          false,
        isGenerating:
          (activeArchetype?.contents.content_topics?.is_querying &&
            searchabilityTopic.isQuerying) ??
          false,
        statusNextStep:
          activeArchetype?.contents.content_topics?.status_next_step ?? false,
      },
      {
        id: StepId.CONTENT_IDEAS,
        label: 'Content Ideas',
        icon: <Icon icon="lucide:sparkles" />,
        status:
          (activeArchetype?.contents.content_ideas?.status ||
            activeArchetype?.contents.last_stepper_status ===
              'content_ideas') ??
          false,
        isGenerating:
          (activeArchetype?.contents.content_ideas?.is_querying &&
            searchabilityIdea.isQuerying) ??
          false,
        statusNextStep:
          activeArchetype?.contents.content_ideas?.status_next_step ?? false,
      },
    ];
  }, [
    activeArchetype,
    searchabilityKeyword, // Gunakan JSON untuk perbandingan deep
    searchabilityTopic,
    searchabilityObjective,
    searchabilityIdea,
  ]); // Dependency untuk memastikan data diperbarui

  useEffect(() => {
    if (!searchabilityData) return;

    const { data, message } = searchabilityData;
    const lastStatus = data[activeTab].contents.last_stepper_status;

    const currentStep = steps.findIndex((step) => step.id === lastStatus);

    setParsedData(data);
    setMessage(message);
    setCurrentStep(currentStep);
    dispatch(saveInformation(''));
  }, [searchabilityData]);

  const handleItemChange = useCallback(
    (
      archetypeId: number,
      recommendationNumber: number,
      title: string,
      id: string,
      checked: boolean,
    ) => {
      const currentStepId = steps[currentStep]?.id as StepId;
      const propertyName = stepPropertyMap[currentStepId];
      const parsedId = parseInt(id, 10);

      submitUpdateSelected(
        {
          archetypeId,
          checked,
          framework: title,
          id: parsedId,
          projectSlug: project.slug,
          recommendationNumber,
          stepName: propertyName,
        },
        {
          onSuccess: () => {
            setParsedData((prevData) => {
              if (!prevData) return prevData;

              const updatedData = prevData.map((section, index) => {
                if (index === activeTab) {
                  const currentStepId = steps[currentStep]?.id as StepId;
                  const propertyName = stepPropertyMap[currentStepId];

                  if (propertyName in section.contents) {
                    const stepDataSection = section.contents[
                      propertyName
                    ] as Section;

                    if (!stepDataSection?.data) return section;

                    const updatedFrameworks = stepDataSection.data.map((fw) => {
                      if (fw.recommendation_number === recommendationNumber) {
                        const updatedFrameworkData = fw.data.map((entry) => {
                          if (
                            entry.title.toLowerCase() === title.toLowerCase()
                          ) {
                            const updatedIdeas = entry.ideas.map((idea) => {
                              if (idea.id.toString() === id) {
                                return { ...idea, is_checked: checked };
                              }
                              return idea;
                            });
                            return { ...entry, ideas: updatedIdeas };
                          }
                          return entry;
                        });

                        return { ...fw, data: updatedFrameworkData };
                      }

                      return fw;
                    });

                    return {
                      ...section,
                      contents: {
                        ...section.contents,
                        [propertyName]: {
                          ...stepDataSection,
                          data: updatedFrameworks,
                        },
                      },
                    };
                  }
                }
                return section; // Tidak ada perubahan pada tab lainnya
              });

              return updatedData;
            });

            refetch();
          },
        },
      );
    },
    [currentStep, activeTab],
  );

  const handleAdd = useCallback(
    (
      formStatus: string,
      archetypeId: number,
      recommendationNumber: number,
      title: string,
      updatedItems: Pick<Idea, 'id' | 'value' | 'is_checked'>[],
    ) => {
      const currentStepId = steps[currentStep].id as StepId;
      const propertyName = stepPropertyMap[currentStepId];

      submitUpdate(
        {
          archetypeId,
          formStatus,
          framework: title,
          projectSlug: project.slug,
          recommendationNumber,
          stepName: propertyName,
          value: updatedItems
            .map((item) => item.value)
            .filter((value): value is string => value !== undefined),
        },
        {
          onSuccess: () => {
            setParsedData((prevData) => {
              if (!prevData) return prevData;

              return prevData.map((section, index) => {
                if (index === activeTab) {
                  const stepDataSection = section.contents[
                    propertyName
                  ] as Section;

                  const newData = stepDataSection.data.map((fw) => {
                    if (fw.recommendation_number === recommendationNumber) {
                      // Perbarui hanya kategori dengan judul yang cocok
                      const updatedFrameworkData = fw.data.map((entry) => {
                        if (entry.title.toLowerCase() === title.toLowerCase()) {
                          const existingIdeasMap = new Map(
                            entry.ideas.map((idea) => [idea.id, idea]),
                          );

                          // Update atau tambahkan `updatedItems`
                          updatedItems.forEach((item) => {
                            const itemId = parseInt(item.id.toString(), 10); // Pastikan ID menjadi angka
                            if (existingIdeasMap.has(itemId)) {
                              // Update nilai yang sudah ada
                              existingIdeasMap.set(itemId, {
                                ...existingIdeasMap.get(itemId),
                                value: item.value,
                                is_checked: item.is_checked,
                                id: itemId, // Pastikan `id` didefinisikan
                              });
                            } else {
                              // Tambahkan item baru
                              existingIdeasMap.set(itemId, {
                                id: itemId,
                                value: item.value,
                                is_checked: item.is_checked,
                              });
                            }
                          });

                          // Konversi kembali ke array
                          const updatedIdeas = Array.from(
                            existingIdeasMap.values(),
                          );

                          return { ...entry, ideas: updatedIdeas };
                        }
                        return entry; // Kategori lain tetap sama
                      });

                      return { ...fw, data: updatedFrameworkData }; // Framework diperbarui
                    }
                    return fw; // Framework lain tetap sama
                  });

                  return {
                    ...section,
                    contents: {
                      ...section.contents,
                      [propertyName]: {
                        ...stepDataSection,
                        data: newData,
                      },
                    },
                  };
                }

                return section;
              });
            });
          },
        },
      );
      // to do change with integration
    },
    [currentStep, activeTab],
  );

  const handleAddOrUpdateIdea = (
    archetypeId: number,
    framework: string,
    title: string,
    newItem: Omit<Idea, 'value'>,
    index?: number,
    callback?: () => void,
  ) => {
    const touchPointNumber = /\d+/.exec(framework)?.[0];

    submitUpdate(
      {
        archetypeId,
        formStatus: index === undefined ? 'add' : 'update',
        framework: title,
        projectSlug: project.slug,
        recommendationNumber: Number(touchPointNumber),
        stepName: stepPropertyMap[steps[currentStep].id as StepId],
        value: newItem,
      },
      {
        onSuccess: () => {
          setParsedData((prevData) => {
            if (!prevData) return prevData;

            return prevData.map((section) => {
              if (section.id === archetypeId) {
                const currentStepId = steps[currentStep].id as StepId;
                const propertyName = stepPropertyMap[currentStepId];
                const stepDataSection = section.contents[
                  propertyName
                ] as Section;

                const newData = stepDataSection.data.map((fw) => {
                  if (fw.framework.toLowerCase() === framework.toLowerCase()) {
                    const updatedFrameworkData = fw.data.map((entry) => {
                      if (entry.title.toLowerCase() === title.toLowerCase()) {
                        const ideas = [...entry.ideas];

                        if (index !== undefined) {
                          if (ideas[index]) {
                            ideas[index] = { ...ideas[index], ...newItem };
                          }
                        } else {
                          // Cek apakah ID sudah ada
                          const existingIndex = ideas.findIndex(
                            (idea) => idea.id && idea.id === newItem.id,
                          );

                          if (existingIndex !== -1) {
                            // Update jika ID ditemukan
                            ideas[existingIndex] = {
                              ...ideas[existingIndex],
                              ...newItem,
                            };
                          } else {
                            // Insert jika ID tidak ditemukan
                            ideas.push(newItem);
                          }
                        }

                        return {
                          ...entry,
                          ideas,
                        };
                      }
                      return entry;
                    });

                    return { ...fw, data: updatedFrameworkData };
                  }
                  return fw;
                });

                return {
                  ...section,
                  contents: {
                    ...section.contents,
                    [propertyName]: {
                      ...stepDataSection,
                      data: newData,
                    },
                  },
                };
              }

              return section;
            });
          });

          if (callback) {
            callback();
          }

          refetch();
        },
      },
    );
  };

  const handleRegenerateButton = (prompt: string) => {
    // TODO: integration with be
    triggerGTMEvent({
      event: `Generate More Searchability Content`,
      eventCategory: `Generate More Searchability Content`,
      eventAction: 'Click',
      eventLabel: 'Searchability Content',
      userId: user.email,
      data: prompt,
    });
  };

  const handleNext = () => {
    if (parsedData && activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        'scrollIntoView' in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        'scrollIntoView' in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handleTabClicked = (title: string) => {
    triggerGTMEvent({
      event: `Tab Searchability content`,
      eventCategory: `Tab Searchability content Click`,
      eventAction: 'Click',
      eventLabel: 'Searchability content',
      userId: user.email,
      data: { title },
    });
  };

  const handleStatusChange = (status: 'idle' | 'adding' | 'editing') => {
    setIsAdding((prevCount) => {
      if (status === 'adding' || status === 'editing') {
        return prevCount + 1;
      }
      if (status === 'idle') {
        return Math.max(0, prevCount - 1); // Pastikan tidak negatif
      }
      return prevCount;
    });
  };

  const handleNoDataFound = (archetype: string) => {
    navigate(
      `/${project.slug}/key-touch-point?archetype=${archetype}&funnel=searchable`,
    );
  };

  const getActiveCategoryTab = (archetypeId: number, framework: string) => {
    return activeCategoryTabState[archetypeId]?.[framework] || 0;
  };

  const updateActiveCategoryTab = (
    archetypeId: number,
    framework: string,
    value: number,
  ) => {
    setActiveCategoryTabState((prevState) => ({
      ...prevState,
      [archetypeId]: {
        ...(prevState[archetypeId] || {}),
        [framework]: value,
      },
    }));
  };

  const addTabCategoryRef = (
    archetypeId: number,
    framework: string,
    index: number,
    element: HTMLElement | null,
  ) => {
    if (!tabsCategoryTypeRef.current[archetypeId]) {
      tabsCategoryTypeRef.current[archetypeId] = {};
    }
    if (!tabsCategoryTypeRef.current[archetypeId][framework]) {
      tabsCategoryTypeRef.current[archetypeId][framework] = [];
    }
    tabsCategoryTypeRef.current[archetypeId][framework][index] = element;
  };

  const updateSelectedTitle = (
    archetypeId: number,
    framework: string,
    title: string,
  ) => {
    setSelectedTitleState((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: title,
      },
    }));
  };

  const getSelectedTitle = (archetypeId: number, framework: string) => {
    return selectedTitleState[archetypeId]?.[framework] || '';
  };

  const getDisplayTitle = (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => {
    const selectedTitle = getSelectedTitle(archetypeId, framework);
    return selectedTitle || data[0]?.title || ''; // Gunakan title pertama sebagai fallback
  };

  const addCardRef = (
    archetypeId: number,
    framework: string,
    title: string,
    index: number,
    ref: CardHandles | null,
  ) => {
    if (!cardRefs.current[archetypeId]) {
      cardRefs.current[archetypeId] = {};
    }
    if (!cardRefs.current[archetypeId][framework]) {
      cardRefs.current[archetypeId][framework] = {};
    }
    if (!cardRefs.current[archetypeId][framework][title]) {
      cardRefs.current[archetypeId][framework][title] = [];
    }

    cardRefs.current[archetypeId][framework][title][index] = ref;
  };

  const handleSaveIdeas = (
    archetypeId: number,
    framework: string,
    title: string,
    index?: number,
  ) => {
    const cards = cardRefs.current[archetypeId]?.[framework]?.[title] || [];
    if (index !== undefined) {
      const card = cards[index];
      if (card) {
        card.submitForm();
      }
    } else {
      cards.forEach((card) => card?.submitForm());
    }
  };

  const isQuerying = useMemo(() => {
    const stepKey = stepPropertyMap[steps[currentStep]?.id as StepId];
    switch (stepKey) {
      case 'goals_success_metrics_main_features':
        return searchabilityObjective.isQuerying;
      case 'keywords_to_rank_up':
        return searchabilityKeyword.isQuerying;
      case 'content_topics':
        return searchabilityTopic.isQuerying;
      case 'content_ideas':
        return searchabilityIdea.isQuerying;
      default:
        return false;
    }
  }, [
    currentStep,
    searchabilityObjective.isQuerying,
    searchabilityKeyword.isQuerying,
    searchabilityTopic.isQuerying,
    searchabilityIdea.isQuerying,
  ]);

  const memoizedData = useMemo(() => {
    if (!parsedData) return [];

    return parsedData
      .filter((_, index) => index === activeTab)
      .map((section) => {
        const currentStepId = steps[currentStep]?.id as StepId;
        const propertyName = stepPropertyMap[currentStepId];
        const stepDataSection = section.contents[propertyName] as Section;

        if (!stepDataSection?.data) return null;

        // Transform `stepDataSection.data`
        const transformedData: Framework[] = stepDataSection.data.map(
          (stepDatum) => ({
            ...stepDatum,
            data: stepDatum.data.map((dataEntry) => ({
              ...dataEntry,
              ideas: dataEntry.ideas?.map((idea) => ({
                ...idea,
                id: idea.id.toString(),
              })),
            })),
          }),
        );

        return { section, transformedData };
      })
      .filter((item): item is NonNullable<typeof item> => !!item); // Type guard
  }, [parsedData, activeTab, currentStep]);

  useEffect(() => {
    if (!memoizedData || memoizedData.length === 0) return;

    const progressIntervals: Record<string, NodeJS.Timeout> = {};
    const refetchIntervals: Record<string, NodeJS.Timeout> = {};

    memoizedData.forEach(({ section }) => {
      const stepKey = stepPropertyMap[steps[currentStep]?.id as StepId];
      const currSection = section.contents[stepKey] as Section;

      if (currSection.is_querying && isQuerying) {
        const sectionId = section.id;

        const initialProgress =
          getProgressFromLocalStorage(project.slug, stepKey, sectionId) || 0;

        // Set progress awal ke state
        setProgressData((prev) => ({
          ...prev,
          [sectionId]: {
            ...prev[sectionId],
            [stepKey]: initialProgress,
          },
        }));

        // Mulai interval untuk progress update
        progressIntervals[sectionId] = setInterval(() => {
          setProgressData((prev) => {
            const currentProgress = prev[sectionId]?.[stepKey] || 0;
            if (currentProgress >= 90) {
              clearInterval(progressIntervals[sectionId]); // Hentikan interval jika progress mencapai 90%
              return prev;
            }

            const updatedProgress = Math.min(currentProgress + 10, 90);

            // Simpan progress ke localStorage
            saveProgressToLocalStorage(
              project.slug,
              stepKey,
              sectionId,
              updatedProgress,
            );

            return {
              ...prev,
              [sectionId]: {
                ...prev[sectionId],
                [stepKey]: updatedProgress,
              },
            };
          });
        }, 3000);

        // Mulai interval untuk refetch
        refetchIntervals[sectionId] = setInterval(() => {
          if (!currSection.is_querying) {
            removeProgressFromLocalStorage(project.slug, stepKey, sectionId);
            clearInterval(refetchIntervals[sectionId]); // Hentikan refetch jika tidak lagi querying
          } else {
            refetch(); // Lakukan refetch
          }
        }, 1000);
      }
    });

    // Bersihkan semua interval saat efek selesai atau dependensi berubah
    return () => {
      Object.values(progressIntervals).forEach(clearInterval);
      Object.values(refetchIntervals).forEach(clearInterval);
    };
  }, [
    memoizedData,
    currentStep,
    stepPropertyMap,
    steps,
    project.slug,
    isQuerying,
  ]);

  useCtaAction({
    searchabilityIdea,
    searchabilityObjective,
    searchabilityKeyword,
    searchabilityTopic,
    message,
    activeTab,
    activeArchetype,
    steps,
    currStep: currentStep,
    isEditor,
    totalArchetypes: parsedData.length,
    projectSlug: project.slug,
    selectedFramework: project.submissions[0].selected_framework,
    onSetCurrentStep: setCurrentStep,
    onSetActiveTab: setActiveTab,
    refetch,
  });

  const handleUpdateLastStatus = useCallback(
    (value: number) => {
      const stepName = stepPropertyMap[steps[value]?.id as StepId];

      updateLastStatus(
        {
          archetypeId: parsedData[activeTab].id,
          projectSlug: project.slug,
          section: 'searchability',
          status: stepName,
        },
        {
          onSuccess: () => setCurrentStep(value),
        },
      );
    },
    [activeTab, parsedData],
  );

  return (
    <div className="size-full">
      <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
        <div className="flex grow flex-col">
          <h1 className="mb-8 inline-flex items-center gap-12 text-25 font-bold leading-none">
            Searchability Content
            <SectionInformation
              description="<b class='text-black-redx'>Searchability</b> — is digital content optimized to rank well on search engines, making it easier for audiences to find. It involves using relevant keywords, structured formatting, and SEO best practices to improve visibility in search results."
              hasButton={false}
              title="What is Searchability Content?"
            />
          </h1>
          <span className="text-1620 font-normal text-grey-redx ">
            Please follow these steps and input the information for each
            archetype to generate content ideas
          </span>
        </div>
      </div>

      {(() => {
        if (message === 'No data found') {
          return (
            <NotDataFound
              buttonLabel="Go to Audience Archetype"
              description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
              onClick={() => navigate(`/${project.slug}/audience-archetype`)}
              title="No Data Found."
            />
          );
        }

        if (project.submissions[0].selected_framework === 'marketing_funnel') {
          return (
            <NotDataFound
              buttonLabel='Go to "Key Touch Points"'
              description="Click the button bellow to go to the Key Touch Point page and change the framework from marketing funnel to S.D.R.C.P."
              onClick={() => navigate(`/${project.slug}/key-touch-point`)}
              title="You did not choose the S.D.R.C.P framework at the key touch point."
            />
          ); // Jangan tampilkan apa-apa
        }

        return (
          <TabGroup
            className="pb-80"
            onChange={setActiveTab}
            selectedIndex={activeTab}
          >
            <div className="relative flex items-center">
              {!isFetching && parsedData && parsedData.length > 3 && (
                <button
                  className="p-2 text-gray-500 hover:text-black"
                  disabled={activeTab === 0}
                  onClick={handlePrev}
                >
                  <Icon className="text-25" icon="mingcute:left-line" />
                </button>
              )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isFetching && parsedData
                  ? parsedData.map((section, index) => (
                      <Tab
                        key={index}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx 
               data-[selected]:border-b-3 data-[selected]:border-blue-redx 
               data-[selected]:font-bold data-[selected]:text-blue-redx 
               data-[focus]:outline-0 data-[focus]:outline-transparent 
               md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                        disabled={isAdding > 0}
                        onClick={() =>
                          handleTabClicked(
                            section.archetype_content.archetype_name,
                          )
                        }
                      >
                        <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                          <div className="flex flex-col gap-8">
                            {`Archetype ${index + 1}`}
                            <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                      >
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                        <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      </button>
                    ))}
              </TabList>
              {!isFetching && parsedData && parsedData.length > 3 && (
                <button
                  className="p-2 text-gray-500 hover:text-black"
                  disabled={activeTab === parsedData.length - 1}
                  onClick={handleNext}
                >
                  <Icon className="text-25" icon="mingcute:right-line" />
                </button>
              )}
            </div>

            {!isFetching && memoizedData?.length ? (
              <TabPanels className="py-24">
                {memoizedData.map(({ section, transformedData }) => {
                  const currSection = section.contents[
                    stepPropertyMap[steps[currentStep]?.id as StepId]
                  ] as Section;

                  return (
                    <div key={`${section.id}_${currentStep}`} className="pt-5">
                      <div className="mt-20" />
                      <ProgressLine
                        isFetching={isFetching}
                        onStepClick={handleUpdateLastStatus}
                        steps={steps}
                      />
                      {currSection.is_querying && isQuerying ? (
                        (() => {
                          const progress =
                            progressData[section.id]?.[
                              stepPropertyMap[steps[currentStep]?.id as StepId]
                            ] || 0;

                          return (
                            <GenerateLoading
                              key={section.id}
                              progress={progressPortion({
                                progress: Number(progress.toFixed(0)),
                                isQuerying:
                                  currSection.is_querying && isQuerying,
                                isContentAvailable: currSection.status,
                              })}
                              project={project}
                              section={{
                                title: 'searchability content',
                                value: `searchability content_${section.archetype_content.archetype_name}`,
                                section:
                                  section.archetype_content.archetype_name,
                              }}
                              showEmailNotification={currSection.is_show_email}
                            />
                          );
                        })()
                      ) : (
                        <>
                          <div className="inline-flex w-full flex-wrap items-center justify-between">
                            <StepHeader currentStep={currentStep} />
                            {isEditor && currentStep > 0 && (
                              <div className="shrink-0">
                                <RegenerateButton
                                  isGenerateMore
                                  limit={currSection.total_generate_more ?? 0}
                                  maxLimit={maxGenerateLimit}
                                  onSubmit={(form) =>
                                    handleRegenerateButton(form.prompt)
                                  }
                                  section={`generate_more_${
                                    stepPropertyMap[
                                      steps[currentStep]?.id as StepId
                                    ]
                                  }`}
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col gap-37">
                            {transformedData.length ? (
                              transformedData.map((stepDatum) => (
                                <div
                                  key={stepDatum.framework}
                                  className="mb-12 flex flex-col"
                                >
                                  <div className="inline-flex gap-12 border-b-1 border-stroke-redx pb-12">
                                    <h2 className="text-16 font-bold leading-20 text-black-redx">
                                      {capitalizeWords(stepDatum.framework)}
                                    </h2>
                                    <SectionInformation
                                      description={stepDatum.description}
                                      hasButton={false}
                                    />
                                  </div>
                                  <div className="mt-12 flex flex-col gap-37">
                                    {steps[currentStep]?.id ===
                                    StepId.CONTENT_IDEAS ? (
                                      <CategoryTabs
                                        addCardRef={addCardRef}
                                        addTabCategoryRef={addTabCategoryRef}
                                        getActiveCategoryTab={
                                          getActiveCategoryTab
                                        }
                                        getDisplayTitle={getDisplayTitle}
                                        isEditor={isEditor}
                                        onHandleAddOrUpdateIdea={
                                          handleAddOrUpdateIdea
                                        }
                                        onHandleSaveIdeas={handleSaveIdeas}
                                        onItemChange={handleItemChange}
                                        project={project}
                                        section={section}
                                        stepDatum={stepDatum}
                                        updateActiveCategoryTab={
                                          updateActiveCategoryTab
                                        }
                                        updateSelectedTitle={
                                          updateSelectedTitle
                                        }
                                        user={user}
                                      />
                                    ) : (
                                      stepDatum.data.map((dataEntry, index) => (
                                        <ChecklistSection
                                          key={`${stepDatum.framework}_${dataEntry.title}_${index}`}
                                          archetypeId={section.id}
                                          isEditor={isEditor}
                                          items={dataEntry.ideas}
                                          onAddItem={handleAdd}
                                          onItemChange={handleItemChange}
                                          onStatusChange={handleStatusChange}
                                          recommendationNumber={
                                            stepDatum.recommendation_number
                                          }
                                          title={dataEntry.title}
                                        />
                                      ))
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <NotDataFound
                                buttonLabel="Go to Key Touch Point"
                                description="Click the button below to choose a searchability touch point in Key Touch Points page."
                                onClick={() =>
                                  handleNoDataFound(section.id.toString())
                                }
                                title="No searchability touch point selected for this archetype."
                              />
                            )}
                          </div>

                          {currSection.sources && (
                            <SourcesList sources={currSection.sources} />
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </TabPanels>
            ) : (
              <Skeleton steps={steps} />
            )}
          </TabGroup>
        );
      })()}
    </div>
  );
};

export default Index;
