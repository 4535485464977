/* eslint-disable unused-imports/no-unused-vars */

import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import EditedLabel from '@/Components/EditedLabel';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import SourcesList from '@/Components/Projects/SourcesList';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import {
  useCtaAction,
  useOnePageStrategy,
  useSectionData,
  useUpdateOnePage,
} from '@/Hooks/OnePageStrategy';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  AudienceInsights,
  DesiredResponse,
  KeyHighlight,
  SectionData,
} from '@/Types/OnePage/one-page-strategy';
import type { Schema } from '@/Types/OnePage/schema';
import { mainSchema } from '@/Types/OnePage/schema';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import SelectingFunnel from '../../Components/Modals/SelectingFunnel';
import CardInput from './Components/CardInput';
import DownloadPDFButton from './Components/DownloadPDFButton';
import SectionCard from './Components/SectionCard';
import type { PropertyKeyMap } from './Components/SectionRenderer';
import SectionRenderer from './Components/SectionRenderer';

const fieldsToDisplay: {
  name: string;
  label: string;
  colSpan: 1 | 2;
  type: 'string' | 'array';
  propertyKey?: keyof PropertyKeyMap;
  fields?: {
    name: keyof AudienceInsights | keyof DesiredResponse | keyof KeyHighlight;
    label: string;
    placeholder: string;
  }[];
}[] = [
  {
    name: 'campaign_strategic_statement',
    label: 'Campaign Objective',
    colSpan: 2,
    type: 'string',
  },
  {
    name: 'challenge',
    label: 'Challenge',
    colSpan: 2,
    type: 'string',
  },
  {
    name: 'communication_task',
    label: 'Communication Task',
    colSpan: 2,
    type: 'string',
  },
  {
    name: 'selected_audience_archetypes',
    label: 'Audience Insights',
    colSpan: 2,
    type: 'array',
    propertyKey: 'audience_insights',
    fields: [
      {
        name: 'pain_point',
        label: 'Pain Point',
        placeholder: 'Input the Pain Point here',
      },
      {
        name: 'lifestyle',
        label: 'Lifestyle',
        placeholder: 'Input the Lifestyle here',
      },
      {
        name: 'behavioral',
        label: 'Behavior',
        placeholder: 'Input the Behavior here',
      },
      {
        name: 'current_trends',
        label: 'Current Trends',
        placeholder: 'Input the Current Trends here',
      },
      {
        name: 'audience_insights',
        label: 'Audience Insights',
        placeholder: 'Input the Audience Insights here',
      },
      {
        name: 'cultural_insights',
        label: 'Cultural Insights',
        placeholder: 'Input the Cultural Insights here',
      },
    ],
  },
  {
    name: 'selected_audience_archetypes',
    label: 'Key Highlight',
    colSpan: 2,
    type: 'array',
    propertyKey: 'key_highlight',
    fields: [
      {
        name: 'emotional',
        label: 'Emotional',
        placeholder: 'Input the Emotional here',
      },
      {
        name: 'functional',
        label: 'Functional',
        placeholder: 'Input the Functional here',
      },
    ],
  },
  {
    name: 'selected_audience_archetypes',
    label: 'Product Feature Benefit',
    colSpan: 1,
    type: 'array',
    propertyKey: 'product_feature_benefit',
    fields: [],
  },
  {
    name: 'selected_audience_archetypes',
    label: 'Strategic Statement',
    colSpan: 1,
    type: 'array',
    propertyKey: 'strategic_statement',
    fields: [],
  },
  {
    name: 'selected_audience_archetypes',
    label: 'Desired Response',
    colSpan: 2,
    type: 'array',
    propertyKey: 'desired_response',
    fields: [
      {
        name: 'before',
        label: 'Before',
        placeholder: 'Input the Before here',
      },
      {
        name: 'after',
        label: 'After',
        placeholder: 'Input the After here',
      },
    ],
  },
];

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);

  const navigate = useNavigate();
  const sectionList: SectionList = {
    title: 'One Page Strategy',
    value: 'onepage',
    section: 'brand',
  };

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Generate Key Touch Point',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
    onClick: () => navigate(`/${project.slug}/key-touch-point`),
  });

  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  const { isKeyTouchPoint, isOnePageStrategy } = useHistoryStatus(
    project,
    state.isEnabledHistoryStatus,
  );

  const [isRegeneratingModal, setIsRegeneratingModal] =
    useState<boolean>(false);
  const [archetypes, setArchetypes] = useState<
    Record<number, { id: number; is_querying: boolean }>
  >({});
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const [sectionData, setSectionData] = useState<Record<string, SectionData>>(
    {},
  );
  const [isEditingCount, setIsEditingCount] = useState(0);

  const { data, isPending, isRefetching, refetch } = useOnePageStrategy(
    project.slug,
  );

  const { mutate: generate, isPending: isGenerating } = useGenerate();

  const [modalFramework, setModalFramework] = useState<boolean>(false);
  useSectionData({
    activeSection: sectionList.value,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionData,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
  });

  const content = sectionData[sectionList.value]?.data ?? {};
  const message = sectionData[sectionList.value]?.message ?? '';
  const isEdited = sectionData[sectionList.value]?.is_edited ?? false;
  const sources = sectionData[sectionList.value]?.sources ?? [];
  const historyId = sectionData[sectionList.value]?.history_id ?? -1;
  const listArchetypeKeyTouchPoints =
    sectionData[sectionList.value]?.list_archetype_key_touch_points ?? [];
  const isQuerying = sectionData[sectionList.value]?.is_querying ?? true;
  const isEmailNotification =
    sectionData[sectionList.value]?.is_email_notification;

  const { mutate, isPending: isUpdating } = useUpdateOnePage();

  const { control, handleSubmit, reset } = useForm<Schema>({
    defaultValues: {
      campaign_strategic_statement: content?.campaign_strategic_statement ?? '',
      challenge: content?.challenge ?? '',
      communication_task: content?.communication_task ?? '',
      selected_audience_archetypes: content?.selected_audience_archetypes ?? [],
    },
    resolver: zodResolver(mainSchema),
  });

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null;
    return archetypes[historyId] || null;
  }, [archetypes, historyId]);

  useRoutePrev({
    id: 'btn-back-one-pages-strategy',
    label: 'Back',
    isActive: message !== 'No record found',
    onClick: () => navigate(`/${project.slug}/opportunity-among-competition`),
  });

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    reset(content);
  }, [content]);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    refetch,
    activeTab: 0,
    projectSlug: project.slug,
    setArchetypes,
    setGeneratingStates: () => {},
    setProgressStates,
    section: sectionList.value,
  });

  const onSubmit = async (data: Schema) => {
    mutate(
      {
        payload: data,
        projectSlug: project.slug,
        historyId: historyId.toString(),
      },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: 'Edit One Page Strategy',
            eventCategory: 'Button Edit One Page Strategy Click',
            eventAction: 'Click',
            eventLabel: 'One Page Strategy',
            userId: user?.email ?? '',
            data,
          });

          refetch();
        },
      },
    );
  };

  const handleCancelEditSection = () => {
    reset(content);
  };

  const handleRegenerateKeyTouchPoint = () => {
    setIsRegeneratingModal(true);
    generate(
      {
        payload: {
          generate_more: '',
          regenerate_prompt: '',
        },
        projectSlug: project.slug,
        section: 'key-touch-point',
      },
      {
        onSuccess: () => {
          setIsRegeneratingModal(false);
          setShowRegenerateModal(false);
          navigate(`/${project.slug}/key-touch-point`);
        },
      },
    );
  };

  useCtaAction({
    message,
    activeSection: sectionList.value,
    isEditing: isEditingCount > 0,
    isEditor,
    project,
    routeOptionValue,
    listArchetypeKeyTouchPoints,
    isEdited,
    setShowRegenerateModal,
    setModalFramework,
    setShowViewInfoModal,
    setRouteOption: setRouteOptionValue,
  });

  const handleRegenerate = useCallback(async () => {
    generate(
      {
        payload: {
          generate_more: '',
          regenerate_prompt: '',
          ...(listArchetypeKeyTouchPoints.length > 0 && {
            status: 'regenerate per archetype from one page',
            archetype_id: listArchetypeKeyTouchPoints,
          }),
          ...(isEdited && {
            status: 'edited one page',
          }),
        },
        projectSlug: project.slug,
        section: 'key-touch-point',
      },
      {
        onSuccess: () => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        },
      },
    );
  }, [listArchetypeKeyTouchPoints.length > 0, isEdited]);

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [isKeyTouchPoint.isQuerying, isKeyTouchPoint.isRegenerated]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex grow flex-col">
            <div className="inline-flex items-center gap-5">
              <h1 className="mb-8 text-25 font-bold leading-none">
                One-Page Strategy
              </h1>
              {isEdited && (
                <div>
                  <EditedLabel />
                </div>
              )}
            </div>

            <span className="text-1620 font-normal text-grey-redx">
              Please review and edit this information, considering that the
              provided reference may not always be accurate.
            </span>
          </div>
          {message !== 'No record found' && (
            <div className="flex items-center">
              <DownloadPDFButton
                isFetching={isPending || isQuerying || isRefetching}
                parsedData={content}
                project={project}
              />
            </div>
          )}
        </div>

        {message === 'No record found' ? (
          <NotDataFound
            buttonLabel="Go to Audience Archetype"
            description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
            onClick={() => navigate(`/${project.slug}/audience-archetype`)}
            title="No Data Found."
          />
        ) : (
          <div className="h-full py-24">
            {isQuerying ? (
              <GenerateLoading
                progress={progressPortion({
                  progress: Number((progressStates[historyId] || 0).toFixed(0)),
                  isQuerying,
                  isContentAvailable: !!content,
                })}
                project={project}
                section={sectionList}
                showEmailNotification={isOnePageStrategy.isSendingEmail}
              />
            ) : (
              <>
                <form
                  className="flex w-full flex-col gap-y-35 pb-60"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {fieldsToDisplay.map(
                    ({ name, label, colSpan, type, fields, propertyKey }) => (
                      <SectionCard
                        key={`${propertyKey}_${name}`}
                        cols={colSpan}
                        disabledSave={isPending}
                        initialEditState={
                          type === 'string' &&
                          typeof content[name as keyof Schema] === 'string'
                            ? (content[name as keyof Schema] as string).trim()
                                .length === 0
                            : undefined
                        }
                        isEditingCount={isEditingCount}
                        isEditor={isEditor}
                        isFetching={isPending || isRefetching || isQuerying}
                        onCancelEdit={handleCancelEditSection}
                        onSubmitEdit={handleSubmit(onSubmit)}
                        setIsEditingCount={setIsEditingCount}
                        title={label}
                      >
                        {({ isEditing }) => {
                          if (type === 'string') {
                            return (
                              <Controller
                                control={control}
                                name={name as keyof Schema}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <CardInput
                                    defaultValue={
                                      typeof content[name as keyof Schema] ===
                                      'string'
                                        ? (content[
                                            name as keyof Schema
                                          ] as string)
                                        : ''
                                    }
                                    error={error?.message}
                                    isEditing={isEditing}
                                    isFetching={
                                      isPending || isRefetching || isQuerying
                                    }
                                    onChange={onChange}
                                    placeholder={`Input the ${label.toLowerCase()} here`}
                                    showTitle={false}
                                    title={label}
                                    value={
                                      typeof value === 'string' ? value : ''
                                    }
                                  />
                                )}
                              />
                            );
                          }

                          if (propertyKey && fields) {
                            return (
                              <SectionRenderer
                                cols={colSpan}
                                control={control}
                                fields={
                                  fields as {
                                    name: keyof PropertyKeyMap[typeof propertyKey];
                                    label: string;
                                    placeholder: string;
                                  }[]
                                }
                                isEditing={isEditing}
                                isFetching={
                                  isPending || isRefetching || isQuerying
                                }
                                items={
                                  content.selected_audience_archetypes || []
                                }
                                propertyKey={propertyKey}
                              />
                            );
                          }

                          return null;
                        }}
                      </SectionCard>
                    ),
                  )}
                </form>
                {sources && <SourcesList sources={sources} />}
              </>
            )}
          </div>
        )}
      </div>
      <SelectingFunnel
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        project={project}
        user={user}
      />
      <RegenerateModal
        handleSubmit={handleRegenerateKeyTouchPoint}
        isOpen={showRegenerateModal}
        isRegenerating={isRegeneratingModal}
        onClose={() => setShowRegenerateModal(false)}
        section="one-page-strategy"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="one-page-strategy"
        visibleSections={[
          'Key Touch Point',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
        ]}
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
