import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState } from 'react';

import EditButton from '@/Components/Projects/EditButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import type {
  DiscoverabilityArchetype,
  Framework,
  Idea,
} from '@/Types/DiscoverabilityContent';
import type { ProjectProps } from '@/Types/Projects';
import type { UserResponse } from '@/Types/user';

import type { CardHandles } from './Card';
import Card from './Card';

const IdeasList = ({
  section,
  stepDatum,
  getDisplayTitle,
  isEditor,
  user,
  project,
  addCardRef,
  onItemChange,
  onHandleSaveIdeas,
  onHandleAddOrUpdateIdea,
}: {
  onHandleAddOrUpdateIdea: (
    archetypeId: number,
    framework: string,
    title: string,
    newItem: Omit<Idea, 'value'>,
    index?: number,
    callback?: () => void,
  ) => void;
  onHandleSaveIdeas: (
    archetypeId: number,
    framework: string,
    title: string,
    index?: number,
  ) => void;
  section: DiscoverabilityArchetype;
  stepDatum: Framework;
  getDisplayTitle: (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => string;
  isEditor: boolean;
  user: UserResponse;
  project: ProjectProps;
  addCardRef: (
    archetypeId: number,
    framework: string,
    title: string,
    index: number,
    ref: CardHandles | null,
  ) => void;
  onItemChange?: (
    archetypeId: number,
    recommendationNumber: number,
    title: string,
    id: string,
    checked: boolean,
  ) => void;
}) => {
  const [editingStatus, setEditingStatus] = useState<
    Record<number, Record<string, Record<string, boolean>>>
  >({});

  const [newIdeas, setNewIdeas] = useState<
    Record<number, Record<string, Record<string, Omit<Idea, 'value'> | null>>>
  >({});
  const [targetIndexes, setTargetIndexes] = useState<
    Record<number, Record<string, Record<string, number>>>
  >({});

  const toggleEditing = (
    archetypeId: number,
    framework: string,
    title: string,
  ) => {
    setEditingStatus((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: {
          ...(prev[archetypeId]?.[framework] || {}),
          [title]: !prev[archetypeId]?.[framework]?.[title], // Toggle status
        },
      },
    }));
  };

  const cancelEditing = (
    archetypeId: number,
    framework: string,
    title: string,
  ) => {
    setEditingStatus((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: {
          ...(prev[archetypeId]?.[framework] || {}),
          [title]: false, // Reset ke false
        },
      },
    }));
  };

  const isEditing = (archetypeId: number, framework: string, title: string) =>
    editingStatus[archetypeId]?.[framework]?.[title] || false;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const filteredEntries = stepDatum.data.filter(
    (dataEntry) =>
      dataEntry.title ===
      getDisplayTitle(section.id, stepDatum.framework, stepDatum.data),
  );

  if (!filteredEntries.length) {
    return <div>No ideas available for this category.</div>;
  }

  const flatCheckedIndexes = filteredEntries.flatMap((dataEntry) =>
    dataEntry.ideas
      .map((idea, index) => (idea.is_checked ? index : -1))
      .filter((index) => index !== -1),
  );

  const handleCancel = (
    archetypeId: number,
    framework: string,
    title: string,
  ) => {
    setNewIdeas((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: {
          ...(prev[archetypeId]?.[framework] || {}),
          [title]: null,
        },
      },
    }));
    setTargetIndexes((prev) => ({
      ...prev,
      [archetypeId]: {
        ...(prev[archetypeId] || {}),
        [framework]: {
          ...(prev[archetypeId]?.[framework] || {}),
          [title]: 0,
        },
      },
    }));
    cancelEditing(archetypeId, framework, title);
  };

  return (
    <>
      <SliderContainer
        key={`${section.id}_${stepDatum.framework}_${filteredEntries[0].title}`}
        goToIndex={
          targetIndexes[section.id]?.[stepDatum.framework]?.[
            filteredEntries[0].title
          ] || -1
        }
        isAdding={false}
        isEditing={false}
        length={filteredEntries[0].ideas.length}
        onActiveIndex={setActiveIndex}
        selectedIndexes={flatCheckedIndexes}
      >
        {filteredEntries[0].ideas.map((idea, idx) => (
          <div key={idx} className="my-24">
            <Card
              ref={(el) => {
                if (el)
                  addCardRef(
                    section.id,
                    stepDatum.framework,
                    filteredEntries[0].title,
                    idx,
                    el,
                  );
              }}
              archetypeId={section.id}
              framework={stepDatum.framework}
              id={+idea.id}
              index={idx}
              isEditing={isEditing(
                section.id,
                stepDatum.framework,
                filteredEntries[0].title,
              )}
              isEditor={isEditor}
              isFetching={false}
              isSelected={idea.is_checked}
              item={{
                id: +idea.id,
                who_should_say_it: idea.who_should_say_it,
                category: idea.category,
                content_idea: idea.content_idea,
                content_format: idea.content_format,
                is_checked: idea.is_checked,
                is_edited: idea.is_edited,
              }}
              onHandleAddOrUpdateIdea={(
                archetypeId: number,
                framework: string,
                title: string,
                newItem: Omit<Idea, 'value'>,
                index?: number,
              ) => {
                onHandleAddOrUpdateIdea(
                  archetypeId,
                  framework,
                  title,
                  newItem,
                  index,
                  () =>
                    cancelEditing(
                      section.id,
                      stepDatum.framework,
                      filteredEntries[0].title,
                    ),
                );
              }}
              onItemChange={onItemChange}
              project={project}
              recommendationNumber={stepDatum.recommendation_number}
              title={stepDatum.data[0].title}
              totalData={filteredEntries[0].ideas.length}
              user={user}
            />
          </div>
        ))}
        {newIdeas[section.id]?.[stepDatum.framework]?.[
          filteredEntries[0].title
        ] && (
          <div className="my-24">
            <Card
              ref={(el) => {
                if (el) {
                  addCardRef(
                    section.id,
                    stepDatum.framework,
                    filteredEntries[0].title,
                    filteredEntries[0].ideas.length, // Index kartu baru
                    el,
                  );
                }
              }}
              archetypeId={section.id}
              framework={stepDatum.framework}
              id={
                newIdeas[section.id][stepDatum.framework][
                  filteredEntries[0].title
                ]?.id ?? 0
              }
              index={filteredEntries[0].ideas.length}
              isEditing
              isEditor={isEditor}
              isFetching={false}
              isSelected={
                newIdeas[section.id][stepDatum.framework][
                  filteredEntries[0].title
                ]?.is_checked
              }
              item={
                newIdeas[section.id][stepDatum.framework][
                  filteredEntries[0].title
                ] ?? undefined
              }
              onHandleAddOrUpdateIdea={(
                archetypeId: number,
                framework: string,
                title: string,
                newItem: Omit<Idea, 'value'>,
              ) => {
                onHandleAddOrUpdateIdea(
                  archetypeId,
                  framework,
                  title,
                  newItem,
                  undefined,
                  () =>
                    handleCancel(
                      section.id,
                      stepDatum.framework,
                      filteredEntries[0].title,
                    ),
                );
              }}
              onItemChange={onItemChange}
              project={project}
              recommendationNumber={stepDatum.recommendation_number}
              title={filteredEntries[0].title}
              totalData={filteredEntries[0].ideas.length + 1}
              user={user}
            />
          </div>
        )}
      </SliderContainer>
      <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
        {isEditor &&
        !isEditing(
          section.id,
          stepDatum.framework,
          filteredEntries[0].title,
        ) ? (
          <button
            className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
            onClick={() => {
              const defaultNewIdea = {
                id: -1, // Gunakan timestamp sebagai ID sementara
                who_should_say_it: '',
                category: '',
                content_idea: '',
                format: '',
                is_checked: false,
              };
              setNewIdeas((prev) => ({
                ...prev,
                [section.id]: {
                  ...(prev[section.id] || {}),
                  [stepDatum.framework]: {
                    ...(prev[section.id]?.[stepDatum.framework] || {}),
                    [filteredEntries[0].title]: defaultNewIdea,
                  },
                },
              }));
              setTargetIndexes((prev) => ({
                ...prev,
                [section.id]: {
                  ...(prev[section.id] || {}),
                  [stepDatum.framework]: {
                    ...(prev[section.id]?.[stepDatum.framework] || {}),
                    [filteredEntries[0].title]: filteredEntries[0].ideas.length,
                  },
                },
              }));
              toggleEditing(
                section.id,
                stepDatum.framework,
                filteredEntries[0].title,
              ); // Masuk ke mode editing
            }}
          >
            Add Content Idea Manually
            <Icon className="size-20" icon="lucide:plus" />
          </button>
        ) : (
          <div />
        )}
        <div className="flex items-center justify-end gap-x-15">
          {isEditor &&
          isEditing(
            section.id,
            stepDatum.framework,
            filteredEntries[0].title,
          ) ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={() => {
                  handleCancel(
                    section.id,
                    stepDatum.framework,
                    filteredEntries[0].title,
                  );
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className={`inline-flex cursor-pointer items-center
                    gap-x-4
                   text-blue-redx`}
                disabled={false}
                id="btn-edit-key-touch-point"
                onClick={() =>
                  onHandleSaveIdeas(
                    section.id,
                    stepDatum.framework,
                    filteredEntries[0].title,
                    activeIndex,
                    // Callback untuk membatalkan mode edit
                  )
                }
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton
              toggleEditing={() =>
                toggleEditing(
                  section.id,
                  stepDatum.framework,
                  filteredEntries[0].title,
                )
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IdeasList;
