import React, { forwardRef } from 'react';

import Content from '@/Components/Content';
import Label from '@/Components/Label';

import TextArea from '../../TextAreaInput';

const CardInput = forwardRef<
  HTMLDivElement,
  {
    isFetching?: boolean;
    isEditing: boolean;
    showTitle?: boolean;
    title: string;
    value?: any;
    defaultValue: any;
    error?: string | undefined;
    onChange?: (e: any) => void;
    placeholder?: string;
  }
>(
  (
    {
      isFetching = false,
      isEditing,
      title,
      value,
      defaultValue,
      error,
      onChange,
      placeholder,
      showTitle = true,
    },
    ref,
  ) => {
    return (
      <div ref={ref} className="flex flex-col gap-4 ">
        {showTitle && <Label value={title} />}
        {isEditing ? (
          <TextArea
            className="overflow-custom mt-1 block w-full resize-none !text-14"
            containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
            error={error}
            name={title.toLowerCase()}
            onChange={onChange}
            placeholder={placeholder}
            rows={2}
            value={value}
          />
        ) : (
          <Content isFetching={isFetching} value={defaultValue} />
        )}
      </div>
    );
  },
);

// Tambahkan display name untuk mempermudah debugging
CardInput.displayName = 'CardInput';

export default CardInput;
