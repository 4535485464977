import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { HistoryStatus } from '@/Types/history';
import type { ArchetypeKeyTouchPoint } from '@/Types/KeyTouchPoint';
import type { ProjectProps } from '@/Types/Projects';

interface UseCtaParams {
  message: string;
  isIdeaExpansion: HistoryStatus;
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeKeyTouchPoint | null;
  totalSelected: number;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  archetypesArray: ArchetypeKeyTouchPoint[];
  setShowRegenerateModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
}

const useCtaAction = ({
  message,
  isIdeaExpansion,
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  isEditing,
  archetypesArray,
  activeArchetype,
  setErrorTabIndex,
  setRouteOption,
  setShowRegenerateModal,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const hasSelected = (archetypesArray: ArchetypeKeyTouchPoint[]) => {
    setErrorTabIndex([]);

    const indicesWithoutSelectedKeyTouchPoint = archetypesArray
      .map((archetype, index) => {
        const hasSelectedTouchPoint = archetype.key_touch_points.some(
          (touchPoint) => touchPoint.total_selected_touchpoint > 0,
        );
        return hasSelectedTouchPoint ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyTouchPoint.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyTouchPoint);

      const namesWithoutSelectedKeyTouchPoint =
        indicesWithoutSelectedKeyTouchPoint
          .map((index) => `Archetype ${index + 1}`) // Sesuaikan nama archetype
          .join(', ');

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select at least one key touch point for ${namesWithoutSelectedKeyTouchPoint}`,
        ),
      );
      return true;
    }

    // Jika semua archetypes valid
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(async () => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Key Touch Point is still generating.`,
        ),
      );
      return;
    }

    if (
      (activeArchetype?.has_regenerate_idea_expansions ||
        activeArchetype?.is_diff_key_touch_point) &&
      isIdeaExpansion.isQuerying
    ) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea Expansion is still generating.`,
        ),
      );
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    const hasError = hasSelected(archetypesArray);

    if (!hasError) {
      if (
        activeArchetype?.has_regenerate_idea_expansions ||
        activeArchetype?.is_diff_key_touch_point
      ) {
        setShowRegenerateModal(true);
        return;
      }

      navigate(`/${project.slug}/creative-idea`);
    }
  }, [
    routeOptionValue,
    isEditing,
    activeArchetype?.is_querying,
    archetypesArray,
    activeArchetype?.has_regenerate_idea_expansions,
    isIdeaExpansion.isQuerying,
    activeArchetype?.is_diff_key_touch_point,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Key Touch Point is still generating.`,
        ),
      );
      return;
    }

    navigate(`/${projectSlug}/creative-idea`);
  }, [activeArchetype?.is_querying, isIdeaExpansion.isQuerying]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    memoizedRouteOptionValue.isInactive =
      activeArchetype?.is_querying ||
      activeArchetype?.total_selected_key_touch_points === 0 ||
      activeArchetype?.key_touch_points.length === 0 ||
      totalSelected === 0 ||
      ((activeArchetype?.has_regenerate_idea_expansions ||
        activeArchetype?.is_diff_key_touch_point) &&
        isIdeaExpansion.isQuerying);

    if (isEditor) {
      if (
        activeArchetype?.has_regenerate_idea_expansions ||
        activeArchetype?.is_diff_key_touch_point
      ) {
        memoizedRouteOptionValue.label = 'Update Other Pages';
        memoizedRouteOptionValue.isGenerate = true;
      } else {
        memoizedRouteOptionValue.label = 'Next to Creative Idea';
        memoizedRouteOptionValue.isGenerate = false;
      }

      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    activeArchetype?.is_querying,
    activeArchetype?.total_selected_key_touch_points,
    activeArchetype?.key_touch_points,
    isEditor,
    totalSelected,
    activeArchetype?.has_regenerate_idea_expansions,
    activeArchetype?.is_diff_key_touch_point,
    isIdeaExpansion.isQuerying,
    message,
  ]);
};

export { useCtaAction };
