import { TabGroup } from '@headlessui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditedLabel from '@/Components/EditedLabel';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import { saveInformation } from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import {
  useCtaAction,
  useMarketResearch,
  useSectionData,
} from '@/Hooks/MarketResearch';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type { SectionData } from '@/Types/MarketResearch';
import type { SectionList } from '@/Types/tabs';
import { cn } from '@/Utils/cn';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import ContentSection from './Components/ContentSection';
import Tabs from './Components/Tabs';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);

  const sectionList: SectionList[] = [
    {
      title: 'Trends',
      value: 'trends',
      section: 'market_research',
    },
    {
      title: 'Demand',
      value: 'demand',
      section: 'market_research',
    },
  ];
  const navigate = useNavigate();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [sectionData, setSectionData] = useState<Record<string, SectionData>>(
    {},
  );
  const {
    isKeyInsightsComplete,
    isMarketResearchDemand,
    isMarketResearchTrend,
  } = useHistoryStatus(project);
  const [archetypes, setArchetypes] = useState<
    Record<
      number,
      {
        id: number;
        is_querying: boolean;
      }
    >
  >({});

  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);

  const activeSection = useMemo(() => {
    return sectionList[activeTab];
  }, [activeTab]);

  const { data, isPending, refetch } = useMarketResearch(
    project.slug,
    activeSection.value,
  );

  const routeOptionValue: RouteOption = {
    label: 'Next to Target Audience',
    isActive: true,
    isDisabled: false,
    onClick: () => navigate(`/${project.slug}/target-audience`),
  };

  const { mutateAsync: generate, isPending: isGenerating } = useGenerate();

  const handlers: Record<
    string,
    (slug: string, prompt: string) => Promise<void>
  > = {
    trends: async (slug: string, prompt: string) => {
      await generate({
        projectSlug: slug,
        section: 'market-research/trends',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      });
    },
    demand: async (slug: string, prompt: string) => {
      await generate({
        projectSlug: slug,
        section: 'market-research/demand',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      });
    },
  };

  useEffect(() => {
    dispatch(saveInformation(''));
  }, []);

  useSectionData({
    data,
    activeSection: activeSection.value,
    projectSlug: project.slug,
    progressStates,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
  });

  const totalRegenerate =
    sectionData[activeSection.value]?.total_regenerate || 0;

  const content = sectionData[activeSection.value]?.content;
  const isDiffProductResearch =
    sectionData[activeSection.value]?.is_diff_market_research;
  const sources = sectionData[activeSection.value]?.sources;
  const historyId = sectionData[activeSection.value]?.history_id;
  const isQuerying = sectionData[activeSection.value]?.is_querying;
  const isEmailNotification =
    activeSection.value === 'trends'
      ? isMarketResearchTrend.isSendingEmail
      : isMarketResearchDemand.isSendingEmail;

  const isRegenerateKeyInsight =
    sectionData[activeSection.value]?.is_regenerate_key_insight;
  const message = sectionData[activeSection.value]?.message;
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null; // Jika tidak ada historyId, kembalikan null
    return archetypes[historyId] || null; // Cari berdasarkan historyId di archetypes
  }, [archetypes, historyId]);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: activeSection.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  useCtaAction({
    activeSection: activeSection.value,
    isEditing,
    isEditor,
    project,
    message,
    routeOptionValue,
    isRegenerateKeyInsight: isRegenerateKeyInsight || isDiffProductResearch,
    setShowViewInfoModal,
    setShowRegenerateModal,
  });

  const handleRegenerateButton = async (section: string, prompt: string) => {
    triggerGTMEvent({
      event: `Regenerate ${section}`,
      eventCategory: `Btn Regenerate ${section} Click`,
      eventAction: 'Click',
      eventLabel: section,
      userId: user.email,
      data: prompt,
    });

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      activeSection.value,
    );

    setSectionData((prev) => ({
      ...prev,
      [activeSection.value]: {
        ...prev[activeSection.value],
        is_querying: true,
      },
    }));

    await handlers[section](project.slug, prompt);

    refetch();
  };

  useRoutePrev({
    id: 'btn-back-market-research',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/product-research`),
  });

  const handleRegenerate = async () => {
    await generate({
      payload: {
        regenerate_prompt: '',
        generate_more: '',
        status: 'edit product research',
      },
      projectSlug: project.slug,
      section: 'key-insight',
    });
    setShowRegenerateModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [isKeyInsightsComplete.isQuerying, isKeyInsightsComplete.isRegenerated]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 w-full bg-white py-16">
          <h1 className="mb-8 text-25 font-bold leading-none">
            Market Research: Industry
          </h1>
          <span className="text-15 font-normal text-grey-redx">
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>
        <TabGroup
          className="pb-80"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs sections={sectionList} user={user} />
          <div className="h-full py-24">
            <div
              className={cn(
                'mb-8 flex w-full items-center',
                isRegenerateKeyInsight ? 'justify-between' : 'justify-end',
              )}
            >
              {isRegenerateKeyInsight && !isQuerying && <EditedLabel />}
              {isEditor && !isQuerying && message !== 'No data found' && (
                <RegenerateButton
                  limit={totalRegenerate}
                  maxLimit={maxGenerateLimit}
                  onSubmit={({ prompt }) =>
                    handleRegenerateButton(activeSection.value, prompt)
                  }
                  section={activeSection.value}
                />
              )}
            </div>
            {(() => {
              if (isPending) {
                return (
                  <ContentSection
                    activeSection={activeSection.value}
                    isLoading
                  />
                );
              }

              if (isQuerying) {
                return (
                  <GenerateLoading
                    progress={progressPortion({
                      progress: Number(
                        (progressStates[historyId] || 0).toFixed(0),
                      ),
                      isQuerying,
                      isContentAvailable: !!content,
                    })}
                    project={project}
                    section={activeSection}
                    showEmailNotification={isEmailNotification}
                  />
                );
              }

              return (
                <ContentSection
                  activeSection={activeSection.value}
                  content={content}
                  id={historyId}
                  isEditor={isEditor}
                  message={message}
                  onEditingChange={setIsEditing}
                  projectSlug={project.slug}
                  refetch={refetch}
                  setShowAlertGenerateModal={setShowAlertGenerateModal}
                  sources={sources}
                />
              );
            })()}
          </div>
        </TabGroup>
      </div>

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="market-research"
      />

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="audience-archetype"
        visibleSections={[
          'Key Insight',
          'Challenge & Communication Task',
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Alignment',
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
          'Selected Content Ideas',
        ]}
      />
    </>
  );
};

export default Index;
