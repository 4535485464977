import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';

import type { ProjectProps } from '@/Types/Projects';
import type {
  Framework,
  Idea,
  SearchabilityArchetype,
} from '@/Types/SearchabilityContent';
import type { UserResponse } from '@/Types/user';

import type { CardHandles } from './Card';
import IdeasList from './IdeaList';

const CategoryTabs = ({
  section,
  stepDatum,
  getActiveCategoryTab,
  updateActiveCategoryTab,
  updateSelectedTitle,
  getDisplayTitle,
  isEditor,
  user,
  project,
  onItemChange,
  addTabCategoryRef,
  addCardRef,
  onHandleAddOrUpdateIdea,
  onHandleSaveIdeas,
}: {
  onHandleSaveIdeas: (
    archetypeId: number,
    framework: string,
    title: string,
    index?: number,
  ) => void;

  onHandleAddOrUpdateIdea: (
    archetypeId: number,
    framework: string,
    title: string,
    newItem: Omit<Idea, 'value'>,
    index?: number,
  ) => void;
  section: SearchabilityArchetype;
  stepDatum: Framework;
  getDisplayTitle: (
    archetypeId: number,
    framework: string,
    data: any[],
  ) => string;
  getActiveCategoryTab: (archetypeId: number, framework: string) => number;
  updateActiveCategoryTab: (
    archetypeId: number,
    framework: string,
    value: number,
  ) => void;
  updateSelectedTitle: (
    archetypeId: number,
    framework: string,
    title: string,
  ) => void;
  isEditor: boolean;
  user: UserResponse;
  project: ProjectProps;
  addCardRef: (
    archetypeId: number,
    framework: string,
    title: string,
    index: number,
    ref: CardHandles | null,
  ) => void;
  addTabCategoryRef: (
    archetypeId: number,
    framework: string,
    index: number,
    element: HTMLElement | null,
  ) => void;
  onItemChange?: (
    archetypeId: number,
    recommendationNumber: number,
    title: string,
    id: string,
    checked: boolean,
  ) => void;
}) => {
  return (
    <TabGroup
      className="flex w-full flex-row gap-24"
      defaultIndex={getActiveCategoryTab(section.id, stepDatum.framework)}
      onChange={(newIndex) =>
        updateActiveCategoryTab(section.id, stepDatum.framework, newIndex)
      }
      selectedIndex={getActiveCategoryTab(section.id, stepDatum.framework)}
    >
      <TabList className="flex w-1/3 max-w-250 flex-col items-start justify-start gap-12 border-r-1 border-r-stroke-redx">
        {stepDatum.data.map((dataEntry, index) => (
          <Tab
            key={`${section.id}_${stepDatum.framework}_${index}`}
            ref={(el) =>
              addTabCategoryRef(section.id, stepDatum.framework, index, el)
            }
            className="inline-flex gap-1 py-12 pr-12 text-start text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
            onClick={() =>
              updateSelectedTitle(
                section.id,
                stepDatum.framework,
                dataEntry.title,
              )
            }
          >
            <p>{index + 1}. </p>
            <p>{dataEntry.title}</p>
          </Tab>
        ))}
      </TabList>

      <TabPanels className="flex w-4/5 grow flex-col">
        <IdeasList
          addCardRef={addCardRef}
          getDisplayTitle={getDisplayTitle}
          isEditor={isEditor}
          onHandleAddOrUpdateIdea={onHandleAddOrUpdateIdea}
          onHandleSaveIdeas={onHandleSaveIdeas}
          onItemChange={onItemChange}
          project={project}
          section={section}
          stepDatum={stepDatum}
          user={user}
        />
      </TabPanels>
    </TabGroup>
  );
};

export default CategoryTabs;
