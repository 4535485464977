/* eslint-disable unused-imports/no-unused-vars */

import { TabGroup } from '@headlessui/react';
import Cookies from 'js-cookie';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import SelectingFunnel from '@/Components/Modals/SelectingFunnel';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import ActionSection from '@/Components/Projects/ActionSection';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import {
  saveInformation,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCtaAction,
  useIdeaExpansions,
  useSectionDataHandler,
  useSubmitIdeaExpansion,
} from '@/Hooks/IdeaExpansion';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  ArchetypeIdeaExpansion,
  ArchetypesState,
  KeyTouchSummary,
  SectionIdeaExpansion,
} from '@/Types/IdeaExpansion';
import type { IdeaExpansionSchema } from '@/Types/IdeaExpansion/schema';
import type { SelectedFramework, SourcesType } from '@/Types/Projects';
import type { SectionList } from '@/Types/tabs';
import { getArchetypeDetails } from '@/Utils/archetype';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { removeAllArchetypesBasedOnSectionFromLocalStorage } from '@/Utils/persist-progress';

import type { CardHandles } from './Components/Card';
import Card from './Components/Card';
import SelectedKeyTouchPointCard from './Components/SelectedKeyTouchPointCard';
import Skeleton from './Components/Skeleton';
import TabListFunnel from './Components/TabListFunnel';
import Tabs from './Components/Tabs';

const Index = () => {
  const [state, dispatch] = useContext(ProjectContext);
  const { mutate: generate } = useGenerate();
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);
  const isEnabledHistoryStatus = useMemo(
    () => state.isEnabledHistoryStatus,
    [state.isEnabledHistoryStatus],
  );

  const [selectedFramework, setSelectedFramework] = useState<SelectedFramework>(
    project.submissions[0].selected_framework,
  );

  const sectionList: SectionList = {
    title: 'Idea Expansion',
    value: 'expansion',
    section: 'idea',
  };
  const navigate = useNavigate();
  const [modalFramework, setModalFramework] = useState<boolean>(false);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const { roles } = useRolesBadge(project, user);
  const [message, setMessage] = useState('');
  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const [archetypes, setArchetypes] = useState<ArchetypesState>({});
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>([]);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const maxGenerateLimit = project.max_generated_data;
  const [activeTab, setActiveTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { data, isLoading, refetch, isRefetching } = useIdeaExpansions(
    project.slug,
  );
  const [totals, setTotals] = useState<{
    totalArchetype: number;
    totalArchetypeSelected: number;
    totalSelectedIdeaExpansion: number;
  }>({
    totalArchetype: 0,
    totalArchetypeSelected: 0,
    totalSelectedIdeaExpansion: 0,
  });
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );
  const { mutate, isPending: isSubmitting } = useSubmitIdeaExpansion();
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({}); // State per archetype
  const [isRegenerating, setIsReGenerating] = useState(false);
  const archetypesArray = useMemo(
    () =>
      Object.values(archetypes).filter(
        (archetype) => archetype.framework === selectedFramework,
      ),
    [archetypes, selectedFramework],
  );

  const [sources, setSources] = useState<SourcesType[]>([]);

  const { refetch: refetchDiffArchetype, data: differentArchetype } =
    useDifferentArchetypes(project.slug);

  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const [selectedFunnel, setSelectedFunnel] = useState<string>('');
  const [selectedRecommendationNumber, setSelectedRecommendationNumber] =
    useState<number>(0);

  const {
    isIdeaExpansion,
    searchabilityObjective,
    discoverabilityObjective,
    credibilityObjective,
  } = useHistoryStatus(project, isEnabledHistoryStatus);

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Next to Searchability Content',
    isActive: true,
    isGenerate: false,
    isInactive: true,
    isDisabled: false,
  });

  const activeArchetype = useMemo(() => {
    return (
      archetypesArray.find(
        (archetype) => archetype.id === archetypesArray[activeTab]?.id,
      ) || null
    );
  }, [archetypesArray, activeTab]);

  const contents = useMemo(
    () =>
      activeArchetype?.idea_expansions.find(
        (item) =>
          item.funnel === selectedFunnel &&
          item.recommendation_number === selectedRecommendationNumber,
      )?.content || [],
    [
      activeArchetype?.idea_expansions,
      selectedFunnel,
      selectedRecommendationNumber,
    ],
  );

  const selectedIndexes = useMemo(() => {
    return contents
      ?.map((item, index) => (item.is_selected ? index : null))
      .filter((index) => index !== null);
  }, [contents]);

  useSectionDataHandler({
    selectedFramework,
    funnel: selectedFunnel,
    archetypes,
    data,
    progressStates,
    projectSlug: project.slug,
    sectionKey: sectionList.value,
    setArchetypes,
    setGeneratingStates,
    setProgressStates,
    setSources,
    setTotalRegenerate,
    setTotals,
    setSelectedFunnel,
    setSelectedRecommendationNumber,
    setActiveTab,
    setMessage,
  });

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab,
    projectSlug: project.slug,
    section: sectionList.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
    isQuerying: isIdeaExpansion.isQuerying,
  });

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  // const limit = activeArchetype?.total_regenerate || 0; TODO: will use this if 'generate more' is generated by archetype

  const handleFetchSubmit = async (
    data: IdeaExpansionSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      {
        payload: data,
        id,
        archetypeId: activeArchetype?.id!,
        projectSlug,
        funnel: selectedFunnel,
        recommendationNumber: selectedRecommendationNumber,
      },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Idea Expansion`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Idea Expansion  Click`,
            eventAction: 'Click',
            eventLabel: 'Idea Expansion',
            userId: user.email,
            data,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          refetch();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  const handleCancelEdit = () => {
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleCancelAdd = () => {
    if (!activeArchetype) return;

    setArchetypes((prev) => {
      const currentIdeaExpansions =
        prev[activeArchetype.id]?.idea_expansions || [];

      const updatedIdeaExpansions = currentIdeaExpansions.map((idea) => {
        if (
          idea.funnel === selectedFunnel &&
          idea.recommendation_number === selectedRecommendationNumber
        ) {
          return {
            ...idea,
            content: idea.content.filter((content) => content.id !== -1),
          };
        }
        return idea;
      });

      return {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          idea_expansions: updatedIdeaExpansions,
        },
      };
    });

    toggleIsAdding();

    // Atur kembali slider ke posisi awal
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleAddNew = () => {
    if (!activeArchetype) return;

    const newData: SectionIdeaExpansion = {
      id: -1, // Gunakan ID sementara
      archetype_id: activeArchetype.id,
      submission_id: -1, // Sesuaikan dengan tipe Anda
      history_id: -1, // Sesuaikan dengan tipe Anda
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_selected: false,
      is_manual: false,
      recommendation_number: selectedRecommendationNumber,
      framework: selectedFramework,
      funnel: selectedFunnel,
      idea_expansion_content: {
        campaign_example: '',
        campaign_metrics: '',
        idea_description: '',
        idea_expansion_number: 0,
        idea_title: '',
        preferred_influencer: '',
        preferred_platform: '',
        reason_audience_archetype: '',
        reason_product_brand: '',
      },
      is_edited: false,
    };

    // Hitung panjang array baru

    const newIndex = contents.length + 1;

    // Update state `archetypes` dengan data baru
    setArchetypes((prev) => {
      const updatedArchetypes = {
        ...prev,
        [activeArchetype.id]: {
          ...prev[activeArchetype.id],
          idea_expansions: prev[activeArchetype.id]?.idea_expansions?.map(
            (idea) => {
              if (
                idea.funnel === selectedFunnel &&
                idea.recommendation_number === selectedRecommendationNumber
              ) {
                return {
                  ...idea,
                  content: [...idea.content, newData],
                };
              }
              return idea;
            },
          ),
        },
      };
      return updatedArchetypes;
    });

    // Setelah state diperbarui, pastikan slider berpindah ke item baru
    setGoToIndex(newIndex);
    toggleIsAdding();
  };

  const handleRegenerate = async () => {};

  const handleRefetchSelectedData = async () => {};

  const handleRegenerateButton = async (prompt: string) => {
    if (!activeArchetype) return;

    // TO DO: will use this code when generate more has per archetype

    // removeProgressFromLocalStorage(
    //   project.slug,
    //   'key_insights',
    //   activeArchetype.id,
    // );

    // setArchetypes((prev) => ({
    //   ...prev,
    //   [activeArchetypeId]: {
    //     ...prev[activeArchetype.id],
    //     is_querying: true,
    //   },
    // }));

    removeAllArchetypesBasedOnSectionFromLocalStorage(
      project.slug,
      sectionList.value,
    );

    setArchetypes((prev) => {
      const updatedArchetypes = Object.keys(prev).reduce<
        Record<number, ArchetypeIdeaExpansion>
      >((acc, key) => {
        const archetypeId = parseInt(key, 10);
        acc[archetypeId] = {
          ...prev[archetypeId],
          is_querying: true,
        };
        return acc;
      }, {});

      return updatedArchetypes;
    });

    triggerGTMEvent({
      event: `Generate More Idea Expansion`,
      eventCategory: `Generate More Idea Expansion Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Expansion',
      userId: user.email,
      data: prompt,
    });

    generate(
      {
        payload: {
          regenerate_prompt: prompt,
          generate_more: '',
        },
        projectSlug: project.slug,
        section: 'idea-expansion',
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(setEnableHistoryStatus(true));
        },
      },
    );
  };

  useEffect(() => {
    dispatch(
      saveInformation(
        `${totals.totalSelectedIdeaExpansion} Idea expansions from ${totals.totalArchetype} Archetype are selected`,
      ),
    );
  }, [totals]);

  useEffect(() => {
    setSelectedFunnel('');
    setSelectedRecommendationNumber(0);
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
    const newFramework = Cookies.get('selected_framework') as SelectedFramework;
    setSelectedFramework(
      newFramework ?? project.submissions[0].selected_framework,
    );
  }, [project.submissions[0].selected_framework]);

  useRoutePrev({
    id: 'btn-back-idea-expansion',
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () => navigate(`/${project.slug}/idea-personalization`),
  });

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  useEffect(() => {
    if (!archetypesArray.length) return;

    const funnel = archetypesArray[activeTab]?.idea_expansions?.[0]?.funnel;
    const recommendationNumber =
      archetypesArray[activeTab]?.idea_expansions?.[0]?.recommendation_number;

    funnel && setSelectedFunnel(funnel);
    recommendationNumber &&
      setSelectedRecommendationNumber(recommendationNumber);
  }, [activeTab]);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedFunnel, activeTab]);

  const handleTitleFunnel = (value: string): string => {
    if (!value) return '';

    return value
      .replace(/\bcredible\b/g, 'credibility')
      .replace(/\bpurchase\b/g, 'purchasability')
      .replace(/able\b/g, 'ability')
      .replace('_funnel', '')
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatSelectedSummaryKeyTouchPoint = (
    keyTouchPoints: KeyTouchSummary[],
  ): string => {
    const formattedSummary = keyTouchPoints.map((point) => {
      const label = handleTitleFunnel(point.funnel_name ?? ''); // Default to funnel_name if not mapped
      return `${label} (${point.total})`;
    });

    return formattedSummary.join(', ');
  };

  const reformatKeyTouchPoints = (data: KeyTouchSummary[]) => {
    return data.flatMap((point) => {
      return (
        point.details?.map((detail, index) => {
          return {
            title: `${handleTitleFunnel(point.funnel_name ?? '')} Touch Point ${
              detail.number ?? index
            }`,
            content: detail.value,
          };
        }) ?? []
      );
    });
  };

  useCtaAction({
    searchabilityObjective,
    discoverabilityObjective,
    credibilityObjective,
    diffArchetype: differentArchetype,
    activeArchetype,
    archetypesArray,
    isEditing: isEditing || isAdding,
    project,
    routeOptionValue,
    setErrorTabIndex,
    setRouteOption: setRouteOptionValue,
    totalSelected: +(totals.totalArchetypeSelected === totals.totalArchetype),
    isEditor,
    message,
    setShowViewInfoModal,
  });

  useEffect(() => {
    if (selectedFunnel === '' && activeArchetype?.idea_expansions.length) {
      const defaultVal = activeArchetype.idea_expansions[0];
      setSelectedFunnel(defaultVal.funnel);
      setSelectedRecommendationNumber(defaultVal.recommendation_number);
    }
  }, [activeArchetype?.idea_expansions, selectedFunnel, selectedFramework]);

  const handleClick = () => {
    navigate(`/${project.slug}/idea-personalization`);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [isIdeaExpansion.isQuerying]);

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex max-w-4xl grow flex-col">
            <h1 className="mb-8 text-25 font-bold leading-none">
              Idea Expansion
            </h1>
            <span className="text-1620 font-normal leading-relaxed text-grey-redx">
              Please{' '}
              <span className="font-bold text-black-redx">
                select one idea for each touch point and archetype
              </span>
              , edit, or add by clicking &apos;Add Idea Expansion
              Manually&apos;.
            </span>
          </div>
          {activeArchetype &&
            isEditor &&
            !activeArchetype.is_querying &&
            message !== 'No data found' && (
              <RegenerateButton
                isGenerateMore
                isLoading={isLoading}
                limit={totalRegenerate} // TODO: change with 'limit', if 'generate more' is generated per archetype
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="idea-expansion"
              />
            )}
        </div>

        <TabGroup
          className="my-24"
          onChange={setActiveTab}
          selectedIndex={activeTab}
        >
          <Tabs
            activeTab={activeTab}
            archetypesArray={archetypesArray}
            errorTabIndex={errorTabIndex}
            isEditing={isEditing || isAdding}
            isLoading={isLoading}
            isQuerying={isIdeaExpansion.isQuerying}
            onActiveTab={setActiveTab}
            user={user}
          />
          {isLoading ? (
            <Skeleton />
          ) : (
            <div
              key={activeArchetype?.id || activeTab}
              className="h-full py-24"
            >
              {(() => {
                if (message === 'No data found') {
                  return (
                    <NotDataFound
                      buttonLabel="Go to Audience Archetype"
                      description="You don't have selected archetype, click the button below to generate a archetype in Audience Archetype page."
                      onClick={() =>
                        navigate(`/${project.slug}/audience-archetype`)
                      }
                      title="No Data Found."
                    />
                  );
                }

                if (!activeArchetype) {
                  return null; // Jangan tampilkan apa-apa
                }

                if (activeArchetype.is_querying) {
                  return (
                    <GenerateLoading
                      progress={progressPortion({
                        progress: Number(
                          (progressStates[activeArchetype.id] || 0).toFixed(0),
                        ),
                        isQuerying: activeArchetype.is_querying,
                        isContentAvailable:
                          activeArchetype.idea_expansions.length > 0,
                      })}
                      project={project}
                      section={sectionList}
                      showEmailNotification={isIdeaExpansion.isSendingEmail}
                    />
                  );
                }

                if (
                  activeArchetype.is_regenerate ||
                  activeArchetype.idea_expansions.length === 0
                ) {
                  return (
                    <NotDataFound
                      buttonLabel="Choose Idea Personalization"
                      description=" To show results on idea expansion, we need idea personalization. Click button below to choose Idea personalization.."
                      onClick={handleClick}
                      title="You don’t idea expansion..."
                    />
                  );
                }

                return (
                  <div className="flex flex-col gap-20">
                    <SelectedCard
                      data={getArchetypeDetails(
                        activeArchetype.archetype_content,
                      )}
                      title={`Archetype Detail - ${activeArchetype.archetype_content.archetype_name}`}
                    />

                    <SelectedKeyTouchPointCard
                      // archetypeId={archetype.id!}
                      data={reformatKeyTouchPoints(
                        activeArchetype?.key_touch_summaries ?? [],
                      ).map((item) => ({
                        ...item,
                        content: item.content ?? '',
                      }))}
                      // projectSlug={project.slug}
                      subtitle={formatSelectedSummaryKeyTouchPoint(
                        activeArchetype?.key_touch_summaries ?? [],
                      )}
                      title={`Selected Touch Point - ${
                        activeArchetype.archetype_content?.archetype_name ?? ''
                      }`}
                    />

                    <TabGroup
                      className="inline-flex gap-24"
                      defaultIndex={activeCategoryTab}
                      onChange={setActiveCategoryTab}
                      selectedIndex={activeCategoryTab}
                    >
                      <TabListFunnel
                        archetypeName={
                          activeArchetype.archetype_content.archetype_name
                        }
                        funnels={activeArchetype.idea_expansions}
                        isEditing={isEditing || isAdding}
                        onHandleTitleFunnel={handleTitleFunnel}
                        onSetSelectedFunnel={setSelectedFunnel}
                        onSetSelectedRecommendationNumber={
                          setSelectedRecommendationNumber
                        }
                        user={user}
                      />
                      <div className="flex w-10/12 flex-col gap-24">
                        <SliderContainer
                          goToIndex={goToIndex}
                          isAdding={isAdding}
                          isEditing={isEditing}
                          length={contents.length}
                          onActiveIndex={(index) => setActiveIndex(index)}
                          selectedIndexes={selectedIndexes}
                        >
                          {contents.map((ideaExpansion, index) => (
                            <Card
                              key={index}
                              ref={(el) => (cardRefs.current[index] = el)}
                              funnel={
                                selectedFunnel === 'marketing_funnel'
                                  ? `${handleTitleFunnel(
                                      selectedFunnel,
                                    )} funnel`
                                  : handleTitleFunnel(selectedFunnel)
                              }
                              id={ideaExpansion.id}
                              index={index}
                              isCanSelect={isEditor}
                              isEdited={
                                ideaExpansion.is_edited ||
                                ideaExpansion.is_manual
                              }
                              isEditing={isEditing || isAdding}
                              isFetching={isRefetching}
                              isSelected={!!ideaExpansion.is_selected}
                              item={ideaExpansion.idea_expansion_content}
                              onSelectSuccess={onSelectSuccess}
                              onSubmitSuccess={handleFetchSubmit}
                              project={project}
                              refetchDiffArchetype={refetchDiffArchetype}
                              selectedNumber={selectedRecommendationNumber}
                              totalData={contents.length}
                              user={user}
                            />
                          ))}
                        </SliderContainer>

                        <ActionSection
                          isEditing={isAdding || isEditing}
                          isEditor={isEditor}
                          isSubmitting={isSubmitting}
                          label={`Add ${handleTitleFunnel(selectedFunnel)} Touchpoint Manually`}
                          onAdd={handleAddNew}
                          onCancel={
                            isAdding ? handleCancelAdd : handleCancelEdit
                          }
                          onSave={handleSubmit}
                          toggleEditing={toggleIsEditing}
                        />
                        {sources && <SourcesList sources={sources} />}
                      </div>
                    </TabGroup>
                  </div>
                );
              })()}
            </div>
          )}
        </TabGroup>
      </div>
      <SelectingFunnel
        defaultSelected={selectedFramework}
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        project={project}
        user={user}
      />
      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="idea-expansion"
      />
      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="idea-expansion"
      />
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
