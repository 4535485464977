import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import type { GetDifferentResponse } from '@/Types/AudienceArchetype';
import type { HistoryStatus } from '@/Types/history';
import type { ArchetypeIdeaExpansion } from '@/Types/IdeaExpansion';
import type { ProjectProps } from '@/Types/Projects';

import { useGenerate } from '../useGenerate';

interface UseCtaParams {
  searchabilityObjective: HistoryStatus;
  discoverabilityObjective: HistoryStatus;
  credibilityObjective: HistoryStatus;
  diffArchetype: GetDifferentResponse | undefined;
  message: string;
  routeOptionValue: RouteOption;
  activeArchetype: ArchetypeIdeaExpansion | null;
  totalSelected: number;
  isEditor: boolean;
  isEditing: boolean;
  project: ProjectProps;
  archetypesArray: ArchetypeIdeaExpansion[];
  setErrorTabIndex: React.Dispatch<React.SetStateAction<number[]>>;
  setRouteOption: React.Dispatch<React.SetStateAction<RouteOption>>;
  setShowViewInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCtaAction = ({
  searchabilityObjective,
  discoverabilityObjective,
  credibilityObjective,
  diffArchetype,
  message,
  isEditor,
  routeOptionValue,
  project,
  totalSelected,
  isEditing,
  archetypesArray,
  activeArchetype,
  setErrorTabIndex,
  setRouteOption,
  setShowViewInfoModal,
}: UseCtaParams) => {
  const [, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const projectSlug = project.slug;
  const { mutate: generate, isPending: isRegenerating } = useGenerate();

  // Memoize routeOptionValue to avoid unnecessary re-render
  const memoizedRouteOptionValue = useMemo(
    () => ({ ...routeOptionValue }),
    [routeOptionValue],
  );

  const hasSelected = (archetypesArray: ArchetypeIdeaExpansion[]) => {
    setErrorTabIndex([]);

    const indicesWithoutSelectedKeyIdeaExpansion = archetypesArray
      .map((archetype, index) => {
        const hasSelectedIdeaExpansion = archetype.idea_expansions.some(
          (ideaExpansion) => ideaExpansion.total_selected_idea_expansions > 0,
        );
        return hasSelectedIdeaExpansion ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyIdeaExpansion.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyIdeaExpansion);

      const namesWithoutSelectedKeyIdeaExpansion =
        indicesWithoutSelectedKeyIdeaExpansion
          .map((index) => `Archetype ${index + 1}`) // Sesuaikan nama archetype
          .join(', ');

      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select at least one idea expansion for ${namesWithoutSelectedKeyIdeaExpansion}`,
        ),
      );
      return true;
    }

    // Jika semua archetypes valid
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleEditorClick = useCallback(async () => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea expansion is still generating.`,
        ),
      );
      return;
    }

    if (isEditing) {
      dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

      setTimeout(() => {
        dispatch(saveSubInformation(''));
      }, 3000);
      return;
    }

    const hasError = hasSelected(archetypesArray);

    if (!hasError) {
      if (
        !searchabilityObjective.status ||
        !discoverabilityObjective.status ||
        !credibilityObjective.status ||
        diffArchetype?.is_different_archetype.searchability_objectives ||
        diffArchetype?.is_different_archetype.credibility_objectives ||
        diffArchetype?.is_different_archetype.discoverability_objectives
      ) {
        generate({
          payload: {
            generate_more: '',
            regenerate_prompt: '',
          },
          projectSlug: project.slug,
          section: 'content/credible/objective',
        });

        generate({
          payload: {
            generate_more: '',
            regenerate_prompt: '',
          },
          projectSlug: project.slug,
          section: 'content/discoverable/objective',
        });

        generate(
          {
            payload: {
              generate_more: '',
              regenerate_prompt: '',
            },
            projectSlug: project.slug,
            section: 'content/searchable/objective',
          },
          {
            onSuccess: () => {
              navigate(`/${project.slug}/searchability-content`);
            },
          },
        );

        return;
      }

      navigate(`/${project.slug}/searchability-content`);
    }
  }, [
    isEditing,
    activeArchetype?.is_querying,
    archetypesArray,
    diffArchetype?.is_different_archetype.searchability_objectives,
    diffArchetype?.is_different_archetype.credibility_objectives,
    diffArchetype?.is_different_archetype.discoverability_objectives,
    searchabilityObjective.status,
    discoverabilityObjective.status,
    credibilityObjective.status,
    searchabilityObjective.isRegenerated,
    discoverabilityObjective.isRegenerated,
    credibilityObjective.isRegenerated,
  ]);

  const handleNonEditorClick = useCallback(() => {
    if (activeArchetype?.is_querying) {
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please wait, Idea Expansion is still generating.`,
        ),
      );
      return;
    }

    if (
      !searchabilityObjective.status ||
      !discoverabilityObjective.status ||
      !credibilityObjective.status ||
      diffArchetype?.is_different_archetype.searchability_objectives ||
      diffArchetype?.is_different_archetype.credibility_objectives ||
      diffArchetype?.is_different_archetype.discoverability_objectives
    ) {
      setShowViewInfoModal(true);
      return;
    }

    navigate(`/${projectSlug}/searchability-content`);
  }, [
    activeArchetype?.is_querying,
    diffArchetype?.is_different_archetype.searchability_objectives,
    diffArchetype?.is_different_archetype.credibility_objectives,
    diffArchetype?.is_different_archetype.discoverability_objectives,
    searchabilityObjective.status,
    discoverabilityObjective.status,
    credibilityObjective.status,
    searchabilityObjective.isRegenerated,
    discoverabilityObjective.isRegenerated,
    credibilityObjective.isRegenerated,
  ]);

  useEffect(() => {
    memoizedRouteOptionValue.isActive = message !== 'No data found';
    memoizedRouteOptionValue.isInactive =
      activeArchetype?.is_querying || totalSelected === 0;

    if (isEditor) {
      if (
        diffArchetype?.is_different_archetype.searchability_objectives ||
        diffArchetype?.is_different_archetype.credibility_objectives ||
        diffArchetype?.is_different_archetype.discoverability_objectives ||
        !searchabilityObjective.status ||
        !discoverabilityObjective.status ||
        !credibilityObjective.status
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Generate Content Objectives';
      } else if (
        searchabilityObjective.isRegenerated ||
        !discoverabilityObjective.isRegenerated ||
        !credibilityObjective.isRegenerated
      ) {
        memoizedRouteOptionValue.isGenerate = true;
        memoizedRouteOptionValue.label = 'Update Other Pages';
      } else {
        memoizedRouteOptionValue.isGenerate = false;
        memoizedRouteOptionValue.label = 'Next to Searchability Content';
      }
      memoizedRouteOptionValue.onClick = handleEditorClick;
    } else {
      memoizedRouteOptionValue.onClick = handleNonEditorClick;
    }

    memoizedRouteOptionValue.isDisabled = isRegenerating;
    setRouteOption(memoizedRouteOptionValue);
    dispatch(saveRouteNext(memoizedRouteOptionValue));
  }, [
    diffArchetype?.is_different_archetype.searchability_objectives,
    diffArchetype?.is_different_archetype.credibility_objectives,
    diffArchetype?.is_different_archetype.discoverability_objectives,
    searchabilityObjective.status,
    discoverabilityObjective.status,
    credibilityObjective.status,
    searchabilityObjective.isRegenerated,
    discoverabilityObjective.isRegenerated,
    credibilityObjective.isRegenerated,
    credibilityObjective.status,
    activeArchetype?.is_querying,
    activeArchetype?.total_selected_idea_expansions,
    activeArchetype?.idea_expansions,
    isEditor,
    totalSelected,
    message,
    isRegenerating,
  ]);
};

export { useCtaAction };
