import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EditedLabel from '@/Components/EditedLabel';
import GenerateLoading from '@/Components/GenerateLoading';
import ChangingImpactModal from '@/Components/Modals/ChangingImpactModal';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import NotDataFound from '@/Components/NotDataFound';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SourcesList from '@/Components/Projects/SourcesList';
import {
  saveInformation,
  saveRouteNext,
  saveSubInformation,
} from '@/Context/Actions/project-actions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/Reducer/project-reducer';
import { useDifferentArchetypes } from '@/Hooks/AudienceArchetype';
import {
  useCompetitors,
  useCtaAction,
  useSectionDataHandler,
  useUpdateCompetitor,
} from '@/Hooks/Competitor';
import { useGenerate } from '@/Hooks/useGenerate';
import { useHistoryStatus } from '@/Hooks/useHistoryStatus';
import { useProgress } from '@/Hooks/useProgress';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { useRoutePrev } from '@/Hooks/useSaveRoutePrev';
import type {
  CompetitorAnalysisContentData,
  SectionData,
} from '@/Types/CompetitorAnalysis';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import {
  initializeGeneratingStates,
  initializeProgressStates,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

import Card from './Components/Card';
import Skeleton from './Components/Skeleton';

const Index = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(ProjectContext);
  const user = useMemo(() => state.user, [state.user]);
  const project = useMemo(() => state.project, [state.project]);

  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');

  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);

  const { data: diffArchetype } = useDifferentArchetypes(project.slug);
  const { mutate: mutateUpdateCompetitor, isPending: isSubmittingCompetitor } =
    useUpdateCompetitor();

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const {
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
  } = useHistoryStatus(project);

  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const { mutate: generate, isPending: isGenerating } = useGenerate();

  const [routeOptionValue, setRouteOptionValue] = useState<RouteOption>({
    label: 'Next to Opportunity Among Competition',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  });

  const sectionList: SectionList = {
    title: 'Competitor Analysis',
    value: '',
    section: 'competitor_analysis',
  };

  const [archetypes, setArchetypes] = useState<
    Record<
      number,
      {
        id: number;
        is_querying: boolean;
      }
    >
  >({});

  const [generatingStates, setGeneratingStates] = useState<
    Record<number, boolean>
  >({});

  const { data, refetch, isPending, isRefetching } = useCompetitors(
    project.slug,
  );

  const [sectionData, setSectionData] = useState<Record<string, SectionData>>(
    {},
  );

  const [isEditingCount, setIsEditingCount] = useState(0);

  const [progressStates, setProgressStates] = useState<Record<number, number>>(
    {},
  );

  const handleClickNotFoundCompetitor = () => {
    navigate(`/${project.slug}/submission?section=3`);
  };

  useSectionDataHandler({
    isDiffArchetype:
      diffArchetype?.is_different_archetype.opportunities ?? false,
    isOpportunityAmongCompetitionComplete,
    data,
    sectionData,
    sectionKey: sectionList.value,
    projectSlug: project.slug,
    initializeGeneratingStates,
    getSectionProgressFromLocalStorage,
    initializeProgressStates,
    setGeneratingStates,
    setProgressStates,
    setSectionData,
    progressStates,
  });

  const handleRegenerateButton = async (prompt: string) => {
    triggerGTMEvent({
      event: `Regenerate Competitor Analysis`,
      eventCategory: `Regenerate Competitor Analysis Click`,
      eventAction: 'Click',
      eventLabel: 'Competitor Analysis',
      userId: user.email,
      data: { slug: project.slug, prompt },
    });

    dispatch(saveInformation(''));

    generate(
      {
        projectSlug: project.slug,
        section: 'competitor-analysis',
        payload: {
          generate_more: '',
          regenerate_prompt: prompt,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const totalRegenerate = sectionData[sectionList.value]?.total_regenerate || 0;

  const message = sectionData[sectionList.value]?.message ?? '';
  const isEdited = sectionData[sectionList.value]?.is_edited ?? false;
  const isDiffCompetitor =
    sectionData[sectionList.value]?.is_diff_competitor ?? false;
  const content = sectionData[sectionList.value]?.content ?? [];
  const sources = sectionData[sectionList.value]?.sources ?? [];
  const historyId = sectionData[sectionList.value]?.history_id ?? -1;
  const isQuerying = sectionData[sectionList.value]?.is_querying ?? false;
  const isEmailNotification = isCompetitorAnalysisComplete.isSendingEmail;

  useEffect(() => {
    if (historyId) {
      setArchetypes((prev) => ({
        ...prev,
        [historyId]: {
          id: historyId,
          is_querying: isQuerying ?? false,
        },
      }));
    }
  }, [historyId, isQuerying]);

  const activeArchetype = useMemo(() => {
    if (!historyId) return null; // Jika tidak ada historyId, kembalikan null
    return archetypes[historyId] || null; // Cari berdasarkan historyId di archetypes
  }, [archetypes, historyId]);

  useEffect(() => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useProgress({
    activeArchetype,
    generatingStates,
    progressStates,
    activeTab: 0,
    projectSlug: project.slug,
    section: sectionList.value,
    refetch,
    setGeneratingStates,
    setArchetypes,
    setProgressStates,
  });

  const handleUpdateChallenge = useCallback(
    (data: CompetitorAnalysisContentData, index: number) => {
      const updatedData = [...content];

      updatedData[index] = { ...updatedData[index], ...data };

      mutateUpdateCompetitor(
        {
          id: historyId,
          payload: updatedData,
          projectSlug: project.slug,
        },
        {
          onSuccess: (data) => {
            data.is_edited &&
              isOpportunityAmongCompetitionComplete.status &&
              !isOpportunityAmongCompetitionComplete.isRegenerated &&
              setShowAlertGenerateModal(data.is_edited);
            refetch();
          },
        },
      );
    },
    [
      isOpportunityAmongCompetitionComplete.status,
      isOpportunityAmongCompetitionComplete.isRegenerated,
    ],
  );

  const checkSelectedArchetypeHasChanging = useCallback(() => {
    queryClient.resetQueries({
      queryKey: ['opportunities', project.slug],
      exact: true,
    });

    if (isEdited || isDiffCompetitor) {
      setShowRegenerateModal(true);
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));

    generate(
      {
        projectSlug: project.slug,
        section: 'opportunity-among-competition',
        payload: {
          generate_more: '',
          regenerate_prompt: '',
        },
      },
      {
        onSuccess: () => {
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
          navigate(`/${project.slug}/opportunity-among-competition`);
        },
      },
    );
  }, [
    routeOptionValue,
    project.slug,
    isEdited,
    isDiffCompetitor,
    isOpportunityAmongCompetitionComplete.isRegenerated,
    diffArchetype?.is_different_archetype.opportunities,
  ]);

  useCtaAction({
    isEdited: isEdited || isDiffCompetitor,
    routeOptionValue,
    isEditor,
    isQuerying,
    isEditingCount,
    project,
    message,
    setShowViewInfoModal,
    checkSelectedArchetypeHasChanging,
    setRouteOption: setRouteOptionValue,
  });

  const handleRegenerate = useCallback(() => {
    triggerGTMEvent({
      event: `Generate Opportunity Among Competition`,
      eventCategory: `Generate Challenge And Task Click`,
      eventAction: 'Click',
      eventLabel: 'Opportunity Among Competition',
      userId: user.email,
      data: project.slug,
    });
    generate(
      {
        projectSlug: project.slug,
        section: 'opportunity-among-competition',
        payload: {
          generate_more: '',
          regenerate_prompt: '',
          ...((isEdited || isDiffCompetitor) && {
            status: 'edited all opportunities from competitor',
          }),
        },
      },
      {
        onSuccess: () => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        },
      },
    );
  }, [isEdited, routeOptionValue, routeOptionValue, isDiffCompetitor]);

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
    fetchData();
  }, [
    isOpportunityAmongCompetitionComplete.isQuerying,
    isChallengeAndTaskOfTheBrand.isQuerying,
    isChallengeAndTaskOfTheBrand.isRegenerated,
  ]);

  useRoutePrev({
    label: 'Back',
    isActive: message !== 'No data found',
    onClick: () =>
      navigate(`/${project.slug}/challenge-and-communication-task`),
  });

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
          <div className="flex grow flex-col">
            <div className="inline-flex items-center gap-5">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Competitor Analysis
              </h1>
              {isEdited && (
                <div>
                  <EditedLabel />
                </div>
              )}
            </div>
            <span className="text-1620 font-normal text-grey-redx">
              Please review and edit this information, considering that the
              provided reference may not always be accurate.
            </span>
          </div>
          {isEditor && !isQuerying && message !== 'No data found' && (
            <div className="flex items-center">
              <RegenerateButton
                isGenerateMore={false}
                limit={totalRegenerate}
                maxLimit={project.max_generated_data} // to do ganti ke max limit
                onSubmit={({ prompt }) => handleRegenerateButton(prompt)}
                section="competitor-analysis"
              />
            </div>
          )}
        </div>

        {isPending ? (
          <Skeleton />
        ) : (
          <div className="h-full py-24">
            {(() => {
              if (isQuerying) {
                return (
                  <GenerateLoading
                    progress={progressPortion({
                      progress: Number(
                        (progressStates[historyId] || 0).toFixed(0),
                      ),
                      isQuerying,
                      isContentAvailable: content.length > 0,
                    })}
                    project={project}
                    section={sectionList}
                    showEmailNotification={isEmailNotification}
                  />
                );
              }

              return (
                <div className="pb-60">
                  {content.length && message !== 'No data found' ? (
                    content.map((item, index) => {
                      return (
                        <Card
                          key={index}
                          index={index}
                          isCanEdit={isEditor}
                          isEditingCount={isEditingCount}
                          isFetching={isRefetching}
                          isSubmitting={isSubmittingCompetitor}
                          item={item}
                          onUpdateChallenge={handleUpdateChallenge}
                          projectSlug={project.slug}
                          refetch={refetch}
                          setIsEditingCount={setIsEditingCount}
                          user={user}
                        />
                      );
                    })
                  ) : (
                    <NotDataFound
                      buttonLabel="Update Competitor Information"
                      description=" We couldn't find any results for Competitor Analysis.
           <br />
           You can input or update the Competitor Information in the submission
           section."
                      onClick={handleClickNotFoundCompetitor}
                      title="No result found..."
                    />
                  )}

                  {sources && content.length ? (
                    <SourcesList sources={sources} />
                  ) : null}
                </div>
              );
            })()}
          </div>
        )}
      </div>

      <ChangingImpactModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isGenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="competitor-analysis"
        visibleSections={[
          'Opportunity Among Competition',
          'One-Page Strategy',
          'Key Touch Point',
          'Idea Alignment',
          'Idea Personalization',
          'Idea Expansion',
          'Searchability Content',
          'Discoverability Content',
          'Credibility Content',
        ]}
      />

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
        section="competitor-analysis"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
