import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';

import type { HistoryStatus } from '@/Types/history';
import type {
  AudienceInsightProps,
  AudienceInsightResponse,
  BehavioralTrendsProps,
  BehavioralTrendsResponse,
  MediaConsumptionPatternsProps,
  MediaConsumptionResponse,
  PerceptionAnalysisProps,
  PerceptionAnalysisResponse,
  SectionDataAudienceInsight,
  SectionDataBehavioralTrends,
  SectionDataMap,
  SectionDataMediaConsumption,
  SectionDataPerceptionAnalysis,
} from '@/Types/TargetAudience';
import {
  initializeGeneratingStates,
  initializeProgressStates,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataParams {
  isAudienceArchetype: HistoryStatus;
  data:
    | AudienceInsightResponse
    | PerceptionAnalysisResponse
    | BehavioralTrendsResponse
    | MediaConsumptionResponse
    | undefined;
  activeSection: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  sectionData: Record<
    keyof SectionDataMap,
    | SectionDataAudienceInsight
    | SectionDataPerceptionAnalysis
    | SectionDataBehavioralTrends
    | SectionDataMediaConsumption
  >;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<
      Record<keyof SectionDataMap, SectionDataMap[keyof SectionDataMap]>
    >
  >;
}

const sanitizeContent = (content: string): string => {
  if (!content || typeof content !== 'string') {
    return ''; // Kembalikan string kosong jika input tidak valid
  }

  return content
    .replace(/[#*~[\]()\-+=`><]/g, '') // Hapus simbol Markdown
    .replace(/[^a-zA-Z\s.]/g, '') // Hapus karakter non-alfabet kecuali spasi dan titik
    .replace(/\s+/g, ' ') // Ganti banyak spasi dengan satu spasi
    .toLowerCase() // Konversi ke huruf kecil
    .trim(); // Hilangkan spasi tambahan di awal/akhir
};

export const useSectionData = ({
  isAudienceArchetype,
  data,
  activeSection,
  projectSlug,
  progressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
  sectionData,
}: UseSectionDataParams) => {
  const compareData = <T>(
    current: T[],
    previous: T[],
    keysToCompare: (keyof T)[],
    cleanFn: (value: string) => string,
  ): boolean => {
    if (current.length !== previous.length) {
      return true;
    }

    for (let i = 0; i < current.length; i++) {
      const currentItem = current[i];
      const previousItem = previous[i];

      for (const key of keysToCompare) {
        const currentItems = currentItem[key] as unknown as {
          title: string;
          content: string;
        }[];
        const previousItems = previousItem[key] as unknown as {
          title: string;
          content: string;
        }[];

        if (currentItems.length !== previousItems.length) {
          return true;
        }

        for (let j = 0; j < currentItems.length; j++) {
          const currentTarget = currentItems[j];
          const previousTarget = previousItems[j];

          if (
            cleanFn(currentTarget.title) !== cleanFn(previousTarget.title) ||
            cleanFn(currentTarget.content) !== cleanFn(previousTarget.content)
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const hasDifferenceAudienceInsight = useCallback(
    (
      current: AudienceInsightProps[],
      previous: AudienceInsightProps[],
    ): boolean => {
      return compareData(
        current,
        previous,
        ['expectations', 'motivations', 'pain_points', 'passion_points'],
        sanitizeContent,
      );
    },
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const hasDifferencePerception = useCallback(
    (
      current: PerceptionAnalysisProps[],
      previous: PerceptionAnalysisProps[],
    ): boolean => {
      return compareData(
        current,
        previous,
        [
          'brand_perception',
          'product_category_perception',
          'sector_perception',
        ],
        sanitizeContent,
      );
    },
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const hasDifferenceBehavioral = useCallback(
    (
      current: BehavioralTrendsProps[],
      previous: BehavioralTrendsProps[],
    ): boolean => {
      return compareData(
        current,
        previous,
        ['consumption_behaviors', 'current_trends'],
        sanitizeContent,
      );
    },
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const hasDifferenceMedia = useCallback(
    (
      current: MediaConsumptionPatternsProps[],
      previous: MediaConsumptionPatternsProps[],
    ): boolean => {
      return compareData(
        current,
        previous,
        ['media_consumption_pattern'],
        sanitizeContent,
      );
    },
    [isAudienceArchetype.isRegenerated, isAudienceArchetype.status],
  );

  const sectionComparators: Record<
    keyof SectionDataMap,
    (current: any, previous: any) => boolean
  > = {
    audience_insight: hasDifferenceAudienceInsight,
    perception_analysis: hasDifferencePerception,
    behavioral_trends: hasDifferenceBehavioral,
    media_consumption_patterns: hasDifferenceMedia,
  };

  useEffect(() => {
    if (data) {
      const existingData = sectionData[activeSection as keyof SectionDataMap];

      if (data.is_querying) {
        const dataArray = [
          {
            id: data.history_id,
            ...data,
          },
        ];

        const initialStates = initializeGeneratingStates(dataArray);
        setGeneratingStates(initialStates);

        const savedProgress = getSectionProgressFromLocalStorage(
          projectSlug,
          activeSection,
        );

        const updatedProgressStates = initializeProgressStates(
          dataArray, // Data array yang sudah diubah
          savedProgress,
          progressStates,
        );

        setProgressStates((prev) => ({
          ...prev,
          ...updatedProgressStates,
        }));
      }

      const comparator =
        sectionComparators[activeSection as keyof SectionDataMap];
      const isDiff = comparator(
        data.content as unknown,
        data.previous_data as unknown,
      );

      if (!isEqual(existingData, data)) {
        setSectionData((prev) => ({
          ...prev,
          [activeSection]: {
            is_email_notification: data.is_email_notification,
            history_id: data.history_id ?? 0,
            content: data.content ?? '',
            sources: data.sources ?? [],
            total_regenerate: data.total_regenerate ?? 0,
            is_querying: data.is_querying,
            is_regenerate_audience: data.is_regenerate_audience,
            is_diff: isDiff,
            message: data.message,
          },
        }));
      }
    }
  }, [data, activeSection]);
};
