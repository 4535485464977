import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';

import type {
  ProductResearchResponse,
  SectionData,
} from '@/Types/ProductResearch';
import { cleanContent } from '@/Utils/clean-content';
import {
  initializeGeneratingStates,
  initializeProgressStates,
} from '@/Utils/init-data';
import { getSectionProgressFromLocalStorage } from '@/Utils/persist-progress';

interface UseSectionDataParams {
  data: ProductResearchResponse | undefined;
  activeSection: string;
  projectSlug: string;
  progressStates: Record<number, number>;
  setGeneratingStates: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  setProgressStates: React.Dispatch<
    React.SetStateAction<Record<number, number>>
  >;
  setSectionData: React.Dispatch<
    React.SetStateAction<Record<string, SectionData>>
  >;
}

export const useSectionData = ({
  data,
  activeSection,
  projectSlug,
  progressStates,
  setGeneratingStates,
  setProgressStates,
  setSectionData,
}: UseSectionDataParams) => {
  const compareContents = useCallback(
    (content1: string, content2: string): boolean => {
      // Bersihkan dan pecah konten menjadi array kalimat
      const lines1 = new Set(cleanContent(content1));
      const lines2 = new Set(cleanContent(content2));

      // Periksa perbedaan
      const diff1 = [...lines1].filter((line) => !lines2.has(line));
      const diff2 = [...lines2].filter((line) => !lines1.has(line));

      // Kembalikan true jika ada perbedaan, false jika tidak
      return diff1.length > 0 || diff2.length > 0;
    },
    [],
  );

  useEffect(() => {
    if (data) {
      const sectionKey = activeSection;

      setSectionData((prev) => {
        const existingData = prev[sectionKey];

        if (data.is_querying) {
          const dataArray = [
            {
              id: data.history_id,
              ...data,
            },
          ];

          const initialStates = initializeGeneratingStates(dataArray);
          setGeneratingStates(initialStates);

          const savedProgress = getSectionProgressFromLocalStorage(
            projectSlug,
            sectionKey,
          );

          const updatedProgressStates = initializeProgressStates(
            dataArray, // Data array yang sudah diubah
            savedProgress,
            progressStates,
          );

          setProgressStates((prev) => ({
            ...prev,
            ...updatedProgressStates,
          }));
        }

        const result = compareContents(
          data.content ?? '',
          data.previous_data ?? '',
        );

        // Update section data jika berbeda
        if (!isEqual(existingData, data)) {
          return {
            ...prev,
            [sectionKey]: {
              is_email_notification: data.is_email_notification,
              history_id: data.history_id ?? 0,
              content: data.content ?? '',
              sources: data.sources ?? [],
              total_regenerate: data.total_regenerate ?? 0,
              is_querying: data.is_querying,
              is_regenerate_key_insight: data.is_regenerate_key_insight,
              is_diff_product_research: result,
              message: data.message,
            },
          };
        }

        return prev;
      });
    }
  }, [data, activeSection]);
};
