import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { goFetcher } from '@/Services/axios-go';
import type {
  HistoryStatus,
  HistoryStatusLatestResponse,
} from '@/Types/history';
import type { ProjectProps } from '@/Types/Projects';

const useHistoryStatus = (project: ProjectProps, isEnabled: boolean = true) => {
  const defaultState: HistoryStatus = useMemo(
    () => ({
      status: false,
      isQuerying: false,
      isRegenerated: false,
      isDisabled: true,
      isSendingEmail: true,
      isError: false,
    }),
    [],
  );

  const fetchLatestStatus = async (): Promise<HistoryStatusLatestResponse> => {
    const url = `/projects/${project.slug}/history/latest`; // Update your API endpoint accordingly
    const response = await goFetcher.get(url);
    if (!response) throw new Error('Network response was not ok');
    return response.data as HistoryStatusLatestResponse;
  };

  const {
    data: response,
    isLoading,
    isError,
    error,
    isFetching,
    isPending,
    isFetched,
    refetch,
  } = useQuery<HistoryStatusLatestResponse>({
    queryKey: ['latestHistoryStatus', project.slug],
    queryFn: fetchLatestStatus,
    refetchInterval: 6000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    enabled: isEnabled ?? true,
  });

  const [isUpdateState, setIsUpdateState] = useState(true);

  const status = useMemo(() => {
    if (!response)
      return {
        isProductResearchProductDescription: defaultState,
        isProductResearchMainFeature: defaultState,
        isProductResearchMarcommHighlight: defaultState,
        isMarketResearchDemand: defaultState,
        isMarketResearchTrend: defaultState,
        isTargetAudienceAudienceInsight: defaultState,
        isTargetAudienceBehavioralTrend: defaultState,
        isTargetAudienceMediaConsumptionPattern: defaultState,
        isTargetAudiencePerceptionAnalysis: defaultState,
        isKeyTouchPoint: defaultState,
        isOnePageStrategy: defaultState,
        isKeyInsightsComplete: defaultState,
        isCompetitorAnalysisComplete: defaultState,
        isOpportunityAmongCompetitionComplete: defaultState,
        isAudienceArchetypeComplete: defaultState,
        isChallengeAndTaskOfTheBrand: defaultState,
        isIdeaSubmission: defaultState,
        isIdeaAlignment: defaultState,
        isIdeaPersonalization: defaultState,
        isIdeaExpansion: defaultState,
        searchabilityIdea: defaultState,
        searchabilityObjective: defaultState,
        searchabilityKeyword: defaultState,
        searchabilityTopic: defaultState,
        discoverabilityIdea: defaultState,
        discoverabilityObjective: defaultState,
        discoverabilityKeyword: defaultState,
        discoverabilityTopic: defaultState,
        credibilityIdea: defaultState,
        credibilityObjective: defaultState,
        credibilityKeyword: defaultState,
        credibilityTopic: defaultState,
        totalCompetitors: 0, // Default value for totalCompetitors
      };

    const { data } = response;

    return {
      isProductResearchProductDescription:
        data.product_research_product_description || defaultState,
      isProductResearchMainFeature:
        data.product_research_main_features || defaultState,
      isProductResearchMarcommHighlight:
        data.product_research_marcomm_highlights || defaultState,
      isMarketResearchDemand: data.market_research_demand || defaultState,
      isMarketResearchTrend: data.market_research_trends || defaultState,
      isTargetAudienceAudienceInsight:
        data.target_audience_audience_insight || defaultState,
      isTargetAudienceBehavioralTrend:
        data.target_audience_behavioral_trends || defaultState,
      isTargetAudienceMediaConsumptionPattern:
        data.target_audience_media_consumption_patterns || defaultState,
      isTargetAudiencePerceptionAnalysis:
        data.target_audience_perception_analysis || defaultState,
      isKeyTouchPoint:
        (project.submissions[0].selected_framework === 'sdrcp'
          ? data.key_touch_point_sdrcp
          : data.key_touch_point_marketing_funnel) || defaultState,
      isOnePageStrategy: data.brand_onepage || defaultState,
      isKeyInsightsComplete: data.key_insights || defaultState,
      isCompetitorAnalysisComplete: data.competitor_analysis || defaultState,
      isOpportunityAmongCompetitionComplete:
        data.opportunity_among_competition || defaultState,
      isAudienceArchetypeComplete: data.audience_archetype || defaultState,
      isChallengeAndTaskOfTheBrand: data.brand_challenges || defaultState,
      isIdeaSubmission: data.idea_submission || defaultState,
      isIdeaAlignment: data.idea_alignment || defaultState,
      isIdeaPersonalization: data.idea_personalization || defaultState,
      isIdeaExpansion: data.idea_expansion || defaultState,

      searchabilityIdea: data.searchability_idea || defaultState,
      searchabilityObjective: data.searchability_objective || defaultState,
      searchabilityKeyword: data.searchability_keywords || defaultState,
      searchabilityTopic: data.searchability_topics || defaultState,
      discoverabilityIdea: data.discoverability_idea || defaultState,
      discoverabilityObjective: data.discoverability_objective || defaultState,
      discoverabilityKeyword: data.discoverability_keyword || defaultState,
      discoverabilityTopic: data.discoverability_topic || defaultState,
      credibilityIdea: data.credibility_idea || defaultState,
      credibilityObjective: data.credibility_objective || defaultState,
      credibilityKeyword: data.credibility_keyword || defaultState,
      credibilityTopic: data.credibility_topic || defaultState,

      totalCompetitors: data.total.competitor,
    };
  }, [response, project.submissions[0].selected_framework]);

  useEffect(() => {
    if (response) {
      setIsUpdateState(false);
    }
  }, [response]);

  return {
    ...status,
    isUpdateState,
    isFetching,
    isLoading,
    isPending,
    isError,
    error,
    refetch,
    isFetched,
  };
};

export { useHistoryStatus };
